const initialState = {
  medias: [],
  events: [],
  deals: [],
  dealsTourist: [],
  selectedMiscellaneous: null,
  selectedDeals: null,
  tenants: [],
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MEDIA":
      return {
        ...state,
        medias: action.payload,
      };
    case "GET_EVENTS":
      return {
        ...state,
        events: action.payload,
      };
    case "SET_SELECTED_MISCELLANEOUS":
      return {
        ...state,
        selectedMiscellaneous: action.payload,
      };
    case "GET_DEALS":
      return {
        ...state,
        deals: action.payload,
      };
    case "GET_DEALS_TOURIST":
      return {
        ...state,
        dealsTourist: action.payload,
      };
    case "GET_TENANTS":
      
      return {
        ...state,
        tenants: action.payload,
      };
    case "SET_SELECTED_DEALS":
      return {
        ...state,
        selectedDeals: action.payload,
      };
    case "RESET_MEDIA":
      return {
        ...state,
        selectedMiscellaneous: null,
        selectedDeals: null,
      };
    default:
      return state;
  }
};

export default mediaReducer;
