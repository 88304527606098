// @flow

import { WayfindingActions, SelectionActions } from '@adactive/arc-map';
import { ModalActions } from '@adactive/adsum-modal-asia';
import { DefaultSchemaHelper } from '@adactive/adsum-client-analytics';
import { ClientAPI as ACA } from '@adactive/adsum-utils';

const trackMapActions = (tracker, store, action) => {
    switch (action.type) {
    case WayfindingActions.types.WILL_DRAW_TO_POI: {
        const poi = ACA.getPoi(action.poiId);
        if (poi !== null) {
            tracker.add(
                DefaultSchemaHelper.getPoiType(),
                DefaultSchemaHelper.getPoiEvent(
                    'goTo',
                    poi,
                ),
            );
        }
        break;
    }
    case ModalActions.SET_POI: {
        if (action.payload && action.payload.poi) {
            tracker.add(
                DefaultSchemaHelper.getPoiType(),
                DefaultSchemaHelper.getPoiEvent(
                    'goTo',
                    action.payload.poi,
                ),
            );
        } else if (action.payload && action.payload.level === null && action.payload.category) {
            tracker.add(
                DefaultSchemaHelper.getCategoryType(),
                DefaultSchemaHelper.getCategoryEvent(
                    'select',
                    action.payload.category,
                ),
            );
        }
        break;
    }
    case WayfindingActions.types.WILL_DRAW_TO_PLACE: {
        const place = ACA.getPlace(action.placeId);
        if (place !== null) {
            tracker.add(
                DefaultSchemaHelper.getPlaceType(),
                DefaultSchemaHelper.getPlaceEvent(
                    'goTo',
                    place,
                ),
            );
            const pois = ACA.getPoisFromPlace(place.id);
            if (pois && pois.length > 0) {
                const poi = pois[0];
                tracker.add(
                    DefaultSchemaHelper.getPoiType(),
                    DefaultSchemaHelper.getPoiEvent(
                        'goTo',
                        poi,
                    ),
                );
                if (poi && poi.categories && Array.from(poi.categories.values).length > 0 && Array.from(poi.categories.values)[0].value) {
                    const id = [Array.from(poi.categories.values)[0].value];
                    const categories = ACA.getCategories(id);
                    if (categories && categories.length > 0) {
                        categories.map((category) => {
                            tracker.add(
                                DefaultSchemaHelper.getCategoryType(),
                                DefaultSchemaHelper.getCategoryEvent(
                                    'goTo',
                                    category,
                                ),
                            );
                            return category;
                        });
                    }
                    if (poi && poi.tags && Array.from(poi.tags.values).length > 0 && Array.from(poi.tags.values)[0].value) {
                        const id = Array.from(poi.tags.values)[0].value;
                        const tags = ACA.getTagBy({ id });
                        if (tags && tags.length > 0) {
                            tags.map((tag) => {
                                tracker.add(
                                    DefaultSchemaHelper.getTagType(),
                                    DefaultSchemaHelper.getTagEvent(
                                        'goTo',
                                        tag,
                                    ),
                                );
                                return tag;
                            });
                        }
                    }
                }
            }
        }
        break;
    }

    case 'SET_SELECTED_POI': {
        const { payload } = action;
        if (payload && payload.name) {
            const place = payload.locations.length > 0 && payload.locations[0];
            if (place !== null) {
                tracker.add(
                    DefaultSchemaHelper.getPlaceType(),
                    DefaultSchemaHelper.getPlaceEvent(
                        'select',
                        place,
                    ),
                );
            }
            const poi = payload;
            tracker.add(
                DefaultSchemaHelper.getPoiType(),
                DefaultSchemaHelper.getPoiEvent(
                    'select',
                    poi,
                ),
            );
            if (poi && poi.categories && Array.from(poi.categories.values).length > 0 && Array.from(poi.categories.values)[0].value) {
                const id = [Array.from(poi.categories.values)[0].value];
                const categories = ACA.getCategories(id);
                if (categories && categories.length > 0) {
                    categories.map((category) => {
                        tracker.add(
                            DefaultSchemaHelper.getCategoryType(),
                            DefaultSchemaHelper.getCategoryEvent(
                                'select',
                                category,
                            ),
                        );
                        return category;
                    });
                }
            }
            if (poi && poi.tags && Array.from(poi.tags.values).length > 0 && Array.from(poi.tags.values)[0].value) {
                const id = [Array.from(poi.tags.values)[0].value];
                const tags = ACA.getTagBy({ id });
                if (tags && tags.length > 0) {
                    tags.map((tag) => {
                        tracker.add(
                            DefaultSchemaHelper.getTagType(),
                            DefaultSchemaHelper.getTagEvent(
                                'select',
                                tag,
                            ),
                        );
                        return tag;
                    });
                }
            }
        }
        break;
    }
    case SelectionActions.types.WILL_SELECT: {
        const { adsumObject } = action;
        if (adsumObject && adsumObject.placeId) {
            const place = ACA.getPlace(adsumObject.placeId);
            if (place !== null) {
                tracker.add(
                    DefaultSchemaHelper.getPlaceType(),
                    DefaultSchemaHelper.getPlaceEvent(
                        'select',
                        place,
                    ),
                );
                const pois = ACA.getPoisFromPlace(place.id);
                if (pois && pois.length > 0) {
                    const poi = pois[0];
                    tracker.add(
                        DefaultSchemaHelper.getPoiType(),
                        DefaultSchemaHelper.getPoiEvent(
                            'select',
                            poi,
                        ),
                    );
                    if (poi && poi.categories && Array.from(poi.categories.values).length > 0 && Array.from(poi.categories.values)[0].value) {
                        const id = [Array.from(poi.categories.values)[0].value];
                        const categories = ACA.getCategories(id);
                        if (categories && categories.length > 0) {
                            categories.map((category) => {
                                tracker.add(
                                    DefaultSchemaHelper.getCategoryType(),
                                    DefaultSchemaHelper.getCategoryEvent(
                                        'select',
                                        category,
                                    ),
                                );
                                return category;
                            });
                        }
                    }
                    if (poi && poi.tags && Array.from(poi.tags.values).length > 0 && Array.from(poi.tags.values)[0].value) {
                        const id = [Array.from(poi.tags.values)[0].value];
                        const tags = ACA.getTagBy({ id });
                        if (tags && tags.length > 0) {
                            tags.map((tag) => {
                                tracker.add(
                                    DefaultSchemaHelper.getTagType(),
                                    DefaultSchemaHelper.getTagEvent(
                                        'select',
                                        tag,
                                    ),
                                );
                                return tag;
                            });
                        }
                    }
                }
            }
        }
        break;
    }
    default:
    }
};

export default trackMapActions;
