/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import { ceil } from "lodash";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { capitalandS3Url } from "../../utils/constants";

const Header = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const navigate = useHistory();
  const [isShowModalConf, setisShowModalConf] = useState(false);
  return (
    <div className="w-full px-4 py-2 flex flex-row justify-between bg-white items-center">
      <div></div>
      <img
        className="w-32 h-12 object-contain"
        src="default.png"
        data-src={`${capitalandS3Url}/media/${generalStore.selectedSite.config.logo}`}
        onError={(element) => {
          element.currentTarget.src = "default.png";
        }}
        onLoad={(element) => {
          element.currentTarget.src =
            element.currentTarget.getAttribute("data-src");
        }}
      />
      <img
        src={require("../../assets/images/icons/mall-selection.png")}
        className="w-[26px] h-[26px] object-contain"
        onClick={() => {
          setisShowModalConf(true);
        }}
      />
      {isShowModalConf && (
        <div className="w-screen h-screen top-0 left-0 fixed z-[100] bg-[#00000042] flex flex-col items-center justify-center p-4">
          <div className="bg-white w-1/2 rounded-lg p-4 flex flex-col">
            <b>Proceed to CapitaLand mall selections?</b>
            <p>
              You will be redirected to a new page to select a new CapitaLand
              mall website. Would you like to continue?
            </p>
            <div className="flex flex-row mt-3">
              <button
                type="button"
                onClick={() => {
                  setisShowModalConf(false);
                }}
                className="text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                style={{ backgroundColor: config.secondaryColor }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  localStorage.removeItem('selectedSite');
                  window.location.href = "/";
                }}
                className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`}
                style={{ backgroundColor: config.primaryColor }}
              >
                Redirect Me
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Header;
