const initialState = {
    isError: false,
};

export const errorReducers = (state = initialState, action) => {
    switch (action.type) {
    case "SET_ERROR":
        return {
            ...state,
            isError: action.isError,
        };
    default:
        return state;
    }
};
