/* eslint-disable */
import { Fragment, useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";

const MallInfoDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const handleScroll = (event) => {
    const target = event.target;
    var clientHeight = target.clientHeight;
    var scrollHeight = target.scrollHeight;
    var scrollTop = target.scrollTop;
    if (scrollHeight - scrollTop - 100 <= clientHeight) {
      var transparencyNum =
        Math.abs(clientHeight - (scrollHeight - scrollTop - 100) - 100) / 100;
      setTransparency(transparencyNum);
    }
  };

  const [transparency, setTransparency] = useState(1);
  const divStyle = {
    "--transparency": transparency,
  };
  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Mall Info"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full h-full p-4">
          <div className="w-full h-full flex flex-row relative overflow-hidden bg-white rounded-lg">
            {config != null && config.conciergeDescription != null ? (
              <Fragment>
                <img
                  src={`${capitalandS3Url}/media/${config.conciergeMedia}`}
                  className="w-80 h-64 aspect-video m-4 rounded-lg"
                />
                <div className="h-full w-full p-4 overflow-y-scroll">
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: config.conciergeDescription,
                    }}
                  />
                </div>
              </Fragment>
            ) : (
              <div className="flex flex-col flex-grow w-full pt-[100px] items-center px-5">
                <img
                  src={require("../assets/images/icons/no-information.png")}
                  className="w-[100px] h-[100px] aspect-square mb-5"
                />
                <div className="bg-white bg-opacity-80 backdrop-blur p-4 rounded-xl">
                  <p className="text-lg text-black-primary-100 mb-2 text-center">
                    No Information.
                  </p>

                  <p className="text-sm text-dark-primary-800 text-start">
                    Apologies, the information that you are looking for is not
                    available yet on the application. Alternatively, you can do
                    one of the following options:
                    <ul
                      className="text-sm ml-4 mt-4"
                      style={{ listStyle: "disc" }}
                    >
                      <li>
                        <span className="font-medium">Visit Concierge</span>
                      </li>
                      <li>
                        <span className="font-medium">Check</span> our website
                        for mor information
                      </li>
                      <li>
                        <span className="font-medium">Chat</span> with us online
                      </li>
                      <li>
                        <span className="font-medium">Contact</span> our Call
                        Centre
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Div100vh>
  );
});

export default MallInfoDesktop;
