/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";
import { Config } from "../utils/const";
import { Tab } from "@headlessui/react";

const PublicTransport = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  const category = ["MRT", "TAXI", "BUS"];
  const [activeTab, setActiveTab] = useState("MRT");
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Public Transport"
          onclick={() => {
            navigate.push(`transport`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full h-full flex flex-col relative overflow-y-scroll">
          {config != null && (
            <div className="h-full w-full flex flex-col overflow-scroll z-10">
              <img
                src={`${Config.api.s3Url}/media/${
                  config[activeTab.toLocaleLowerCase() + "Media"]
                }`}
                className="w-full"
              />
              <div className="p-3 m-3 bg-white rounded-lg">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl  p-1">
                    {category.map((c, idx) => {
                      return (
                        config[c.toLocaleLowerCase() + "Media"] != null && (
                          <Tab
                            key={c}
                            className={({ selected }) =>
                              classNames(
                                `w-full py-2.5 text-sm font-medium focus:outline-none border-[${config.primaryColor}]`,
                                selected
                                  ? "bg-white border-b-2 text-gray-900"
                                  : "text-gray-400"
                              )
                            }
                            onClick={() => {
                              setActiveTab(c);
                            }}
                          >
                            {c}
                          </Tab>
                        )
                      );
                    })}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {config.mrtMedia != null && (
                      <Tab.Panel
                        key={0}
                        className={classNames("rounded-xl bg-white p-3", "")}
                      >
                        <div className="flex flex-col gap-3">
                          {config.mrt.map((item, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row w-full gap-3 items-center"
                              >
                                <img
                                  src={`${Config.api.s3Url}/media/${item.logo}`}
                                  className="w-6 h-6 object-fit"
                                />
                                <div
                                  className={`${
                                    idx == 0
                                    ? "flex-grow font-medium"
                                    : "flex-grow"
                                  }`}
                                  style={{lineBreak:'anywhere'}}
                                >
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Tab.Panel>
                    )}
                    {config.taxiMedia != null && (
                      <Tab.Panel
                        key={1}
                        className={classNames("rounded-xl bg-white p-3", "")}
                      >
                        <div className="flex flex-col gap-3">
                          {config.taxi.map((item, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row w-full gap-3 items-center"
                              >
                                <img
                                  src={`${Config.api.s3Url}/media/${item.logo}`}
                                  className="w-6 h-6 object-fit"
                                />
                                <div
                                  className={`${
                                    idx == 0
                                      ? "flex-grow font-medium"
                                      : "flex-grow"
                                  }`}
                                  style={{lineBreak:'anywhere'}}
                                >
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Tab.Panel>
                    )}
                    {config.busMedia != null && (
                      <Tab.Panel
                        key={2}
                        className={classNames("rounded-xl bg-white p-3", "")}
                      >
                        <div className="flex flex-col gap-3">
                          {config.bus.map((item, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row w-full gap-3 items-center"
                              >
                                <img
                                  src={`${Config.api.s3Url}/media/${item.logo}`}
                                  className="w-6 h-6 object-fit"
                                />
                                <div
                                  className={`${
                                    idx == 0
                                    ? "flex-grow font-medium"
                                    : "flex-grow"
                                  }`}
                                  style={{lineBreak:'anywhere'}}
                                >
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Tab.Panel>
                    )}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          )}
        </div>
      </div>
    </Div100vh>
  );
});

export default PublicTransport;
