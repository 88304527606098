/* eslint-disable */
import { useStores } from "../../use.store";
import React from "react";
import { observer } from "mobx-react-lite";

import { FiClock, FiWifi, IconName } from "react-icons/fi";
import { capitalandS3Url } from "../../utils/constants";
const Background = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  return config.backgroundMobile ? (
    config.backgroundMobile.split(".")[
      config.backgroundMobile.split(".").length - 1
    ] === "mp4" ? (
      <video
        autoPlay
        muted
        loop
        className="object-cover absolute z-[-1] w-full h-full"
      >
        <source
          src={`${capitalandS3Url}/media/${config.backgroundMobile}`}
          type="video/mp4"
        />
      </video>
    ) : (
      <img
        src={`${capitalandS3Url}/media/${config.backgroundMobile}`}
        className="object-cover absolute z-[-1] w-full h-full"
        alt=""
      />
    )
  ) : (
    <div className="absolute z-[-1] w-full h-full bg-splashscreen-bg"></div>
  );
});

export default Background;
