import Const, {
    Config
} from '../../../utils/const';
import {
    API_TOKEN,
    AWM,
    CATEGORIES_TENANT,
    HAPPENINGS,
    IS_LOADING,
    PRIMARY_COLOR,
    PROMOTIONS,
    SECONDARY_COLOR,
    SELECTED_MALL,
    SET_AWM,
    SITES,
    TENANTS,
    TOURIST_DEAL
} from './constants';

const INIT_STATE = {
    selectedMall: sessionStorage.getItem('selectedMall') != null ? JSON.parse(sessionStorage.getItem('selectedMall')) : null,
    sites: [],
    secondaryColor: Const.secondaryColor,
    primaryColor: Const.mainColor,
    promotions: [],
    tenants: [],
    categoriesTenant: [],
    happenings: [],
    touristDeal : [],
    isLoading: false,
    awm: null
};

const Adsign = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SELECTED_MALL:
            return {
                ...state,
                selectedMall: action.payload
            };

        case SITES:
            return {
                ...state,
                sites: action.payload
            };

        case API_TOKEN:
            return {
                ...state,
                apiToken: action.payload
            };

        case PRIMARY_COLOR:
            return {
                ...state,
                primaryColor: action.payload
            };
        case SECONDARY_COLOR:
            return {
                ...state,
                secondaryColor: action.payload
            };

        case PROMOTIONS:
            return {
                ...state,
                promotions: action.payload
            };

        case TENANTS:
            return {
                ...state,
                tenants: action.payload
            };

        case CATEGORIES_TENANT:
            return {
                ...state,
                categoriesTenant: action.payload
            };

        case SET_AWM:
            return {
                ...state,
                awm: action.payload
            };
        case HAPPENINGS:
            return {
                ...state,
                happenings: action.payload
            };
        case TOURIST_DEAL:
            return {
                ...state,
                touristDeal: action.payload
            };

        default:
            return {
                ...state
            };
    }
}

export default Adsign;