export const LIST_MALL = "LIST_MALL";
export const SELECTED_MALL = "SELECTED_MALL";
export const SET_AWM = "SET_AWM";

// adsign
export const API_TOKEN = "API_TOKEN";
export const SITES = "SITES";
export const PRIMARY_COLOR = "PRIMARY_COLOR";
export const SECONDARY_COLOR = "SECONDARY_COLOR";
export const PROMOTIONS = "PROMOTIONS";
export const HAPPENINGS = "HAPPENINGS";
export const TOURIST_DEAL = "TOURIST_DEAL";
export const TENANTS = "TENANTS";
export const CATEGORIES_TENANT = "CATEGORIES_TENANT";