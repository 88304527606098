/* eslint-disable */
import { useStores } from "../../use.store";
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { FaReply, FaTimes, FaWalking } from "react-icons/fa";
import Const, { Config } from "../../utils/const";
import { HiCalendar, HiLocationMarker, HiX } from "react-icons/hi";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import moment from "moment";
import Tags from "./Tags";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DetailDeal = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const [isPathAvailable, setIsPathAvailable] = useState(false);
  const [isPathLiftAvailable, setIsPathLiftAvailable] = useState(false);
  const navigate = useHistory();

  const { wayFindingControlsState, currentSite, isActionEnabled } = useSelector(
    (state) => ({
      poiType: state.app.poiType,
      isWayfinding: state.app.isWayfinding,
      disableTmtState: state.disableTmt,
      wayFindingControlsState: state.wayFindingControls,
      awm: state.app.awm,
      currentFloor: state.map.currentFloor,
      floors: state.app.floors,
      getPath: state.map.getPath,
      isActionEnabled: state.app.isActionEnabled,
      wayfindingState: state.map.wayfindingState,
      currentSite: state.capitaland.currentSite,
    })
  );

  const selectedDeal = generalStore.deal;
  const [detail, setDetail] = useState(true);
  const sheetRef = useRef();

  useEffect(() => {
    if (selectedDeal) {
      if (selectedDeal.source && selectedDeal.source === "adsign") {
        if (selectedDeal && selectedDeal.tenants) {
          if (selectedDeal.tenants.length === 1) {
            findPoiBy("name", selectedDeal.tenants[0]);
          }
        }
      } else {
        if (selectedDeal && selectedDeal._rel_tenants_details) {
          if (
            selectedDeal._rel_tenants_details.length &&
            selectedDeal._rel_tenants_details.length > 0
          ) {
            findPoiBy(
              "name",
              selectedDeal._rel_tenants_details[0]["jcr:title"].trim()
            );
          }
        }
      }
    }
  }, [selectedDeal]);

  const findPois = (alias) => {
    const findPoi = generalStore.pois.filter((x) =>
      alias.includes(x.client_id)
    );
    return _.map(findPoi, "name").join(", ");
  };

  const findPoiBy = (type, value) => {
    const findPoi = generalStore.pois.find(
      (x) => x[type].trim() === value.trim()
    );
    if (findPoi) {
      generalStore.to = findPoi;
    }
  };

  return (
    selectedDeal && (
      <div className="w-full h-full z-[999] fixed top-0 bg-[#00000057] flex items-center justify-center">
        <div className="mx-auto bg-white w-1/2 absolute shadow-lg rounded-lg max-h-[40vw] flex flex-col">
          <div className="flex flex-row justify-between items-center p-4">
            <span className="font-semibold">Detail</span>
            <HiX
              fontSize={20}
              onClick={() => {
                // setOpen(false)
                generalStore.to = null;
                generalStore.deal = null;
              }}
            />
          </div>
          <div className="w-full h-full px-4 pb-4 flex flex-row overflow-y-scroll gap-4">
            <img
              loader={
                <img
                  src={require("../../assets/images/icons/img-default.png")}
                />
              }
              src={`${Config.api.capitalandExternalUrl}/${selectedDeal.thumbnail}`}
              className=" object-cover w-72 h-64 rounded-lg"
            />
            <div className="w-full flex flex-col overflow-y-scroll">
              <h1 className="font-semibold text-lg">
                {selectedDeal["jcr:title"]}
              </h1>
              <span className="font-thin text-sm text-gray mt-4">
                {selectedDeal._rel_tenants_details[0]["jcr:title"]}
              </span>
              <div
                className="flex text-sm flex-row gap-2 divide-x-2 divide-gray-300"
                style={{ color: `${config.primaryColor}` }}
              >
                {
                  <div className="flex flex-row items-center">
                    <HiLocationMarker />
                    <span className="ml-1">
                      {selectedDeal.source &&
                      selectedDeal.source === "adsign" ? (
                        findPois(selectedDeal.tenants).length > 80 ? (
                          <div className="running-text-3">
                            <span
                              style={{
                                animationDuration: `${
                                  findPois(selectedDeal.tenants).length * 0.25
                                }s`,
                              }}
                            >
                              {findPois(selectedDeal.tenants)}
                            </span>
                          </div>
                        ) : (
                          findPois(selectedDeal.tenants)
                        )
                      ) : (
                        selectedDeal._rel_tenants_details[0]["jcr:title"]
                      )}
                    </span>
                  </div>
                }
                {selectedDeal.startdate != null && (
                  <div className="flex flex-row items-center pl-1">
                    <HiCalendar />
                    <span className="ml-1 text-sm">
                      {selectedDeal.source && selectedDeal.source === "adsign"
                        ? selectedDeal.start && selectedDeal.end
                          ? `${moment(new Date(selectedDeal.start)).format(
                              "DD/MM/YYYY"
                            )} - ${moment(new Date(selectedDeal.end)).format(
                              "DD/MM/YYYY"
                            )}`
                          : selectedDeal.start && !selectedDeal.end
                          ? moment(new Date(selectedDeal.start)).format(
                              "DD/MM/YYYY"
                            )
                          : !selectedDeal.start && selectedDeal.end
                          ? `Ends at ${moment(
                              new Date(selectedDeal.end)
                            ).format("DD/MM/YYYY")}`
                          : "All Day"
                        : selectedDeal.startdate && selectedDeal.enddate
                        ? `${moment(new Date(selectedDeal.startdate)).format(
                            "DD/MM/YYYY"
                          )} - ${moment(new Date(selectedDeal.enddate)).format(
                            "DD/MM/YYYY"
                          )}`
                        : selectedDeal.startdate && !selectedDeal.enddate
                        ? moment(new Date(selectedDeal.startdate)).format(
                            "DD/MM/YYYY"
                          )
                        : !selectedDeal.startdate && selectedDeal.enddate
                        ? `Ends at ${moment(
                            new Date(selectedDeal.enddate)
                          ).format("DD/MM/YYYY")}`
                        : "All Day"}
                    </span>
                  </div>
                )}
              </div>
              {generalStore.to != null && (
                <Tags selectedPoi={generalStore.to} />
              )}
              <hr className="my-4 border-t border-gray-200" />
              <div
                className="w-full text-sm"
                dangerouslySetInnerHTML={{
                  __html: selectedDeal["jcr:description"],
                }}
              ></div>
            </div>
          </div>
          <div className="w-full flex flex-row gap-1">
            <div
              onClick={(e) => {
                generalStore.tmtType = "";
                generalStore.deal = null;
                navigate.push("/floor-guide");
              }}
              className={`flex-1 bg-[${
                config ? config.tertiaryColor : "#F19743"
              }] h-16 flex items-center justify-center rounded-bl-lg`}
            >
              <FaWalking fontSize={20} color="white" />
              <span className="font-semibold text-white text-sm">
                { generalStore.selectedSite.name == "Clarke Quay" ? "STAIRS" : "ESCALATOR"}
              </span>
            </div>
            <div
              onClick={(e) => {
                generalStore.deal = null;
                generalStore.tmtType = "";
                navigate.push("/floor-guide");
              }}
              className={`flex-1 bg-[${
                config ? config.secondaryColor : "#F19743"
              }] h-16 flex items-center justify-center rounded-br-lg`}
            >
              <img
                src={require("../../assets/images/icons/lift.png")}
                className="w-5 mr-2"
              />
              <span className="font-semibold text-white text-sm">LIFT</span>
            </div>
          </div>
        </div>
      </div>
      // <BottomSheet
      // scrollLocking={false}
      //   open={selectedDeal != null}
      //   snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1.18]}
      //   // Opens the largest snap point by default, unless the user selected one previously
      //   defaultSnap={({ lastSnap, snapPoints }) =>
      //     lastSnap ?? Math.max(...snapPoints)
      //   }
      //   draggable={false}
      //   header={
      //     <div className="flex flex-row justify-between">
      //       <span className=" font-semibold">Detail</span>
      //       <HiX
      //         fontSize={20}
      //         onClick={() => {
      //           // setOpen(false)
      //           generalStore.to = null;
      //           generalStore.deal = null;
      //         }}
      //       />
      //     </div>
      //   }
      //   className="z-50 absolute"
      // >
      //   {selectedDeal != null && (
      //     <React.Fragment>
      //       <div className="flex flex-col-reverse w-full h-full">
      //         <div className="flex flex-row gap-0.5 fixed bottom-0 z-50 w-full">
      //           <div
      //             onClick={(e) => {
      //               generalStore.tmtType = "";
      //               generalStore.deal = null;
      //               navigate.push("/floor-guide");
      //             }}
      //             className={`flex-1 bg-[${
      //               config ? config.tertiaryColor : "#F19743"
      //             }] h-16 flex items-center justify-center`}
      //           >
      //             <FaWalking fontSize={20} color="white" />
      //             <span className="font-semibold text-white text-sm">
      //               { generalStore.selectedSite.name == "Clarke Quay" ? "STAIRS" : "ESCALATOR"}
      //             </span>
      //           </div>
      //           <div
      //             onClick={(e) => {
      //               generalStore.deal = null;
      //               generalStore.tmtType = "";
      //               navigate.push("/floor-guide");
      //             }}
      //             className={`flex-1 bg-[${
      //               config ? config.secondaryColor : "#F19743"
      //             }] h-16 flex items-center justify-center`}
      //           >
      //             <img
      //               src={require("../../assets/images/icons/lift.png")}
      //               className="w-5 mr-2"
      //             />
      //             <span className="font-semibold text-white text-sm">LIFT</span>
      //           </div>
      //         </div>
      //         <div className="flex-grow w-full flex-col overflow-scroll pb-[100px] border-b">
      //           <img
      //             loader={
      //               <img
      //                 src={require("../../assets/images/icons/img-default.png")}
      //               />
      //             }
      //             src={`${Config.api.capitalandExternalUrl}/${selectedDeal.thumbnail}`}
      //             className=" object-cover w-full"
      //           />
      //           <div className="p-2">
      //             <h1 className="font-semibold text-lg">
      //               {selectedDeal["jcr:title"]}
      //             </h1>
      //             <span className="font-thin text-sm text-gray mt-4">
      //               {selectedDeal._rel_tenants_details[0]["jcr:title"]}
      //             </span>
      //             <div
      //               className="flex text-sm flex-row gap-2 divide-x-2 divide-gray-300"
      //               style={{ color: `${config.primaryColor}` }}
      //             >
      //               {
      //                 <div className="flex flex-row items-center">
      //                   <HiLocationMarker />
      //                   <span className="ml-1">
      //                     {selectedDeal.source &&
      //                     selectedDeal.source === "adsign" ? (
      //                       findPois(selectedDeal.tenants).length > 80 ? (
      //                         <div className="running-text-3">
      //                           <span
      //                             style={{
      //                               animationDuration: `${
      //                                 findPois(selectedDeal.tenants).length *
      //                                 0.25
      //                               }s`,
      //                             }}
      //                           >
      //                             {findPois(selectedDeal.tenants)}
      //                           </span>
      //                         </div>
      //                       ) : (
      //                         findPois(selectedDeal.tenants)
      //                       )
      //                     ) : (
      //                       selectedDeal._rel_tenants_details[0]["jcr:title"]
      //                     )}
      //                   </span>
      //                 </div>
      //               }
      //               {selectedDeal.startdate != null && (
      //                 <div className="flex flex-row items-center pl-1">
      //                   <HiCalendar />
      //                   <span className="ml-1 text-sm">
      //                     {selectedDeal.source &&
      //                     selectedDeal.source === "adsign"
      //                       ? selectedDeal.start && selectedDeal.end
      //                         ? `${moment(new Date(selectedDeal.start)).format(
      //                             "DD/MM/YYYY"
      //                           )} - ${moment(
      //                             new Date(selectedDeal.end)
      //                           ).format("DD/MM/YYYY")}`
      //                         : selectedDeal.start && !selectedDeal.end
      //                         ? moment(new Date(selectedDeal.start)).format(
      //                             "DD/MM/YYYY"
      //                           )
      //                         : !selectedDeal.start && selectedDeal.end
      //                         ? `Ends at ${moment(
      //                             new Date(selectedDeal.end)
      //                           ).format("DD/MM/YYYY")}`
      //                         : "All Day"
      //                       : selectedDeal.startdate && selectedDeal.enddate
      //                       ? `${moment(
      //                           new Date(selectedDeal.startdate)
      //                         ).format("DD/MM/YYYY")} - ${moment(
      //                           new Date(selectedDeal.enddate)
      //                         ).format("DD/MM/YYYY")}`
      //                       : selectedDeal.startdate && !selectedDeal.enddate
      //                       ? moment(new Date(selectedDeal.startdate)).format(
      //                           "DD/MM/YYYY"
      //                         )
      //                       : !selectedDeal.startdate && selectedDeal.enddate
      //                       ? `Ends at ${moment(
      //                           new Date(selectedDeal.enddate)
      //                         ).format("DD/MM/YYYY")}`
      //                       : "All Day"}
      //                   </span>
      //                 </div>
      //               )}
      //             </div>
      //             {generalStore.to != null && (
      //               <Tags selectedPoi={generalStore.to} />
      //             )}
      //             <hr className="my-4 border-t border-gray-200" />
      //             <div
      //               className="w-full text-sm"
      //               dangerouslySetInnerHTML={{
      //                 __html: selectedDeal["jcr:description"],
      //               }}
      //             ></div>
      //           </div>
      //         </div>
      //       </div>
      //     </React.Fragment>
      //   )}
      // </BottomSheet>
    )
  );
});

export default DetailDeal;
