/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { s3Url } from "../../utils/constants";
import { useStores } from "../../use.store";
import { Config } from "../../utils/const";
import { HiLocationMarker } from "react-icons/hi";
import _ from "lodash";
import EmptyState from "./EmptyState";

const SearchView = observer(() => {
  const { generalStore } = useStores();
  const [query, setQuery] = useState("");
  const [poiList, setPoiList] = useState([]);
  const [searchHistory, setSearchHistroy] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout); // Clear previous timeout
    const value = event.target.value;
    timeout = setTimeout(() => {
      setQuery(value);
      if (value != "") {
        submitSearch(value);
      }
    }, 300);
  };

  const submitSearch = (q) => {
    const findAmenities = generalStore.selectedSite.categories.find(
      (cat) => cat.name === "Amenities"
    );
    let data = generalStore.pois
      .filter(
        (poi) => !_.map(poi.categoriesDetails, "id").includes(findAmenities.id) && !_.map(poi.categoriesDetails, "name").includes("Find My Car")
      )
      .filter((poi) => poi.locations.length > 0)
      .filter((poi) => {
        if (generalStore.searchAction == "from") {
          if (generalStore.to != null) {
            return poi.id != generalStore.to.id;
          } else {
            return true;
          }
        } else {
          if (generalStore.from != null) {
            return poi.id != generalStore.from.id;
          } else {
            return true;
          }
        }
      })
      .filter((e) => {
        return e.name.toLocaleLowerCase().includes(q.toLocaleLowerCase());
      })
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
  };

  const initData = () => {
    const findAmenities = generalStore.selectedSite.categories.find(
      (cat) => cat.name === "Amenities"
    );
    let data = generalStore.pois
      .filter(
        (poi) => !_.map(poi.categoriesDetails, "id").includes(findAmenities.id) && !_.map(poi.categoriesDetails, "name").includes("Find My Car")
      )
      .filter((poi) => poi.locations.length > 0)
      .filter((poi) => {
        if (generalStore.searchAction == "from") {
          if (generalStore.to != null) {
            return poi.id != generalStore.to.id;
          } else {
            return true;
          }
        } else {
          if (generalStore.from != null) {
            return poi.id != generalStore.from.id;
          } else {
            return true;
          }
        }
      })
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
    if (generalStore.searchAction == "from") {
      setSearchHistroy(generalStore.historyFrom);
    } else {
      setSearchHistroy(generalStore.historyTo);
    }
  };

  return (
    <div className="w-full h-full flex flex-col bg-whiteBg absolute z-[999999]">
      <div className="flex flex-row w-full items-center justify-center gap-2 px-4 pt-6 pb-3 overflow-hidden">
        <div className="flex-grow relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white">
          <input
            className="peer h-full w-full outline-none text-sm text-gray-700 px-3 rounded-lg"
            type="text"
            id="search"
            onChange={handleInputChange}
            placeholder={
              generalStore.searchAction == "from"
                ? "Where are you at?"
                : "Where are you going to?"
            }
          />
          <div className="grid place-items-center h-full w-12 text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
        <img
          onClick={() => {
            generalStore.searchAction = "";
          }}
          src={require("../../assets/images/icons/close.png")}
          className="h-7 w-7"
        />
      </div>
      <div className="h-full w-full overflow-y-scroll gap-3 flex flex-col">
        <span className="font-medium px-4">Result</span>
        {
          <div className="w-full grid grid-cols-1 gap-3 px-4 content-start ">
            {poiList.length == 0 && <EmptyState title={"No Result Found"} description={"Sorry, try adjusting your search to find what you are looking for. "} />}
            {poiList.map((e) => {
              return (
                <div
                  b
                  key={e.group + "group"}
                  className="w-full h-auto flex flex-col gap-2"
                >
                  <div className="flex flex-col w-full gap-3">
                    {e.children.map((poi) => {
                      return (
                        <div
                          className="bg-white shadow rounded-lg flex flex-row gap-2"
                          key={poi.id}
                          onClick={() => {
                            if (generalStore.searchAction == "from") {
                              generalStore.from = poi;
                            } else {
                              generalStore.to = poi;
                            }
                            generalStore.searchAction = "";
                          }}
                        >
                          <img
                            src="default.png"
                            data-src={
                              poi.logo != null
                                ? `${Config.api.capitalandExternalUrl}${poi.logo}`
                                : "default.png"
                            }
                            onError={(element) => {
                              element.currentTarget.src = "default.png";
                            }}
                            onLoad={(element) => {
                              element.currentTarget.src =
                                element.currentTarget.getAttribute("data-src");
                            }}
                            className="object-contain w-20 h-20 rounded-s-lg"
                          />
                          <div className="flex-grow flex flex-col items-start justify-between py-2">
                            <div className="w-full flex flex-col">
                              <h2 className="text-sm font-medium line-clamp-1">
                                {poi.name}
                              </h2>
                              <span className="text-xs text-slate-500">
                                {_.map(poi.categoriesDetails, "name").join(
                                  ", "
                                )}
                              </span>
                            </div>

                            <div className="flex flex-row gap-1 items-center justify-center">
                              <HiLocationMarker fontSize={16} color="#008BAF" />
                              <div className="flex-grow">
                                <span className="text-[#008BAF] font-light text-xs line-clamp-1">
                                  {poi.locations.length > 0
                                    ? _.map(poi.locations, "name")
                                        .join("-")
                                        .replaceAll("_", " ")
                                    : generalStore.selectedSite.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
});

export default SearchView;
