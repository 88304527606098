/* eslint-disable */
// @flow
import React, { useState, useEffect, Fragment } from "react";
import Routes from "./Routes";
import { clearSessionStorageOnHotReload } from "./utils/clearSessionStorageOnHotReload";

clearSessionStorageOnHotReload();
function App() {
  return <main>
  <Routes/>
</main>
}

export default App;
 