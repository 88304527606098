/* eslint-disable */
import { useStores } from "../../use.store";
import React from "react";
import { observer } from "mobx-react-lite";

import { FiClock, FiWifi, IconName } from "react-icons/fi";
import { capitalandS3Url } from "../../utils/constants";
import { Config } from "../../utils/const";
const Tags = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const selectedPoi = generalStore.to;
  // console.log(selectedPoi, "selectedPoi");
  // console.log(config, "config");
  return (
    <div className="flex flex-wrap mt-3 w-full">
      {/* {selectedPoi.new && <div className="bg-[#B00B10] text-xs text-white mr-2 px-2">
      New Store  
      </div>} */}
      {selectedPoi.storeStatus &&
        selectedPoi.storeStatus == "new-store" &&
        config.newstore && (
          <div className="flex items-center mr-2">
            <img
              src={`${Config.api.s3Url}/media/${config.newstore}`}
              className="h-[15px] mr-[10px]"
              alt="newstore"
            />
          </div>
        )}
      {selectedPoi.storeStatus &&
        selectedPoi.storeStatus == "opening-soon" &&
        config.openingsoon && (
          <div className="flex items-center mr-2">
            <img
              src={`${Config.api.s3Url}/media/${config.openingsoon}`}
              className="h-[15px] mr-[10px]"
              alt="openingsoon"
            />
          </div>
        )}
      {selectedPoi.storeStatus &&
        selectedPoi.storeStatus == "temporarily-closed" &&
        config.closed && (
          <div className="flex items-center mr-2">
            <img
              src={`${Config.api.s3Url}/media/${config.closed}`}
              className="h-[15px] mr-[10px]"
              alt="closed"
            />
          </div>
        )}
      {/* {selectedPoi.acceptsCapitavoucher ? (
        <div className="flex items-center mr-2">
          <img
            src={
              config.capitavoucher
                ? `${Config.api.s3Url}/media/${config.capitavoucher}`
                : require("../../assets/images/icons/voucher.png")
            }
            className="w-[15px] h-[15px] mr-[10px]"
            alt="capitavoucher"
          />
          <p className="text-black-primary-100 text-xs">CapitaVoucher</p>
        </div>
      ) : null} */}

      {selectedPoi.acceptseCapitavoucher ? (
        <div className="flex items-center mr-2">
          <img
            src={
              config.ecapitavoucher
                ? `${Config.api.s3Url}/media/${config.ecapitavoucher}`
                : require("../../assets/images/icons/star-voucher.png")
            }
            className="w-[15px] h-[15px] mr-[10px]"
            alt="halal"
          />
          <p className="text-black-primary-100 text-xs">eCapitaVoucher</p>
        </div>
      ) : null}

      {selectedPoi.acceptsDbsInstantEarn ? (
        <div className="flex items-center mr-2">
          <img
            src={
              config.dbsinstantearn
                ? `${Config.api.s3Url}/media/${config.dbsinstantearn}`
                : require("../../assets/images/icons/key-voucher.png")
            }
            className="w-[20px] h-[20px] mr-[10px]"
            alt="dbs"
          />
          <p className="text-black-primary-100 text-xs">
            DBS (Instant STAR$®️ Earn)
          </p>
        </div>
      ) : null}

      {selectedPoi.acceptsCapitacard ? (
        <div className="flex items-center mr-2">
          <img
            src={
              config.capitacard
                ? `${Config.api.s3Url}/media/${config.capitacard}`
                : require("../../assets/images/icons/capitacard.png")
            }
            className="w-[15px] h-[15px] mr-[10px]"
            alt="capitacard"
          />
          <p className="text-black-primary-100 text-xs">CapitaCard</p>
        </div>
      ) : null}

      {selectedPoi.acceptsHalalCertified ? (
        <div className="flex items-center w-full  mr-[24px]">
          <img
            src={
              config.halalcertified
                ? `${Config.api.s3Url}/media/${config.halalcertified}`
                : require("../../assets/images/icons/halal.png")
            }
            className="w-[15px] h-[15px] mr-[10px]"
            alt="halal"
          />
          <p className="text-black-primary-100 text-xs">Halal</p>
        </div>
      ) : null}
    </div>
  );
});

export default Tags;
