/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";
import { Config } from "../utils/const";
import Lottie from "react-lottie";

const Transport = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Transport"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full h-full flex flex-col gap-4 relative overflow-y-scroll p-4">
          <div
            onClick={() => {
              navigate.push('/carpark-rate')
            }}
            className="flex-col-1 relative h-[142px]"
          >
            {config.carparkBackgroundMobile == null ? (
              <img
                src={require(`../assets/images/icons/img-default.png`)}
                className="w-full h-full object-cover absolute inset-0 rounded-lg"
              />
            ) : (
              <img
                className="w-full h-full object-cover absolute inset-0 rounded-lg"
                src={
                  config != null
                    ? `${Config.api.s3Url}/media/${config.carparkBackgroundMobile}`
                    : "../assets/images/icons/img-default.png"
                }
                alt=""
              />
            )}
            <div
              className="w-full h-full absolute inset-0 rounded-lg"
              style={{ backgroundColor: "#00000059" }}
            ></div>
            <div className="w-full h-full top-0 flex flex-col absolute  items-center justify-center">
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  borderRadius: "32px",
                  backgroundColor: "#ffffff57",
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: require(`../assets/images/animation/carpark-rates.json`),
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  style={{ flex: 1, pointerEvents: "none" }}
                />
              </div>
              <span className="text-white font-semibold mt-2 px-4 w-52 text-center uppercase ">
                Carpark Rates
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              navigate.push('/public-transport')
              // dispatch(setSelectedPage("publicTransport"));
            }}
            className="flex-col-1 relative h-[142px]"
          >
            {config.publictransportBackgroundMobile == null ? (
              <img
                src={require(`../assets/images/icons/img-default.png`)}
                className="w-full h-full object-cover absolute inset-0 rounded-lg"
              />
            ) : (
              <img
                className="w-full h-full object-cover absolute inset-0 rounded-lg"
                src={
                  config != null
                    ? `${Config.api.s3Url}/media/${config.publictransportBackgroundMobile}`
                    : "../assets/images/icons/img-default.png"
                }
                alt=""
              />
            )}
            <div
              className="w-full h-full absolute inset-0 rounded-lg"
              style={{ backgroundColor: "#00000059" }}
            ></div>
            <div className="w-full h-full top-0 flex flex-col absolute  items-center justify-center">
              {/* <img src={require('../assets/images/icons/public_information_icon.png')} /> */}
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  borderRadius: "32px",
                  backgroundColor: "#ffffff57",
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: require(`../assets/images/animation/public-trans.json`),
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  style={{ flex: 1, pointerEvents: "none" }}
                />
              </div>
              <span className="text-white font-semibold mt-2 px-4 w-52 text-center uppercase ">
                PUBLIC TRANSPORTATION INFORMATION
              </span>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
});

export default Transport;
