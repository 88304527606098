/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { Tab } from "@headlessui/react";
import { capitalandS3Url, capitalandUrl } from "../utils/constants";
import { Config } from "../utils/const";
import DetailDeal from "./components/DetailDeal";

const TouristDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  const category = ["TOURIST PRIVILEGES", "TOURIST EDEALS"];
  const dispatch = useDispatch();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleScroll = (event) => {
    const target = event.target;
    var clientHeight = target.clientHeight;
    var scrollHeight = target.scrollHeight;
    var scrollTop = target.scrollTop;
    if (scrollHeight - scrollTop - 100 <= clientHeight) {
      var transparencyNum =
        Math.abs(clientHeight - (scrollHeight - scrollTop - 100) - 100) / 100;
      setTransparency(transparencyNum);
    }
  };

  const [transparency, setTransparency] = useState(1);
  const divStyle = {
    "--transparency": transparency,
  };
  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Tourist Programme"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full h-full p-4">
          <div className="w-full h-full flex flex-col relative overflow-hidden bg-white rounded-lg">
            {config != null && config.touristMediaMobile != null ? (
              <div className="w-full h-full flex flex-row gap-4">
                <div className="w-2/3">
                  <img
                    src={`${capitalandS3Url}/media/${config.touristMediaMobile}`}
                    className="w-full  aspect-video m-4 rounded-lg"
                  />
                </div>
                <div
                  className={`w-full p-4 rounded-lg flex flex-col overflow-y-scroll`}
                >
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-t-xl bg-white ">
                      {category.map((c, idx) => {
                        return (
                          <Tab
                            key={c}
                            className={({ selected }) =>
                              classNames(
                                `w-full rounded-t-xl py-2.5 text-sm focus:outline-none font-medium border-[${config.primaryColor}]`,
                                selected
                                  ? "bg-white border-b-[3px] text-gray-900"
                                  : "text-gray-400"
                              )
                            }
                          >
                            {c}
                          </Tab>
                        );
                      })}
                    </Tab.List>
                    <Tab.Panels className="flex-grow overflow-y-scroll">
                      <Tab.Panel
                        key={0}
                        className={classNames(
                          "rounded-b-xl h-full overflow-y-scroll bg-white px-3 pb-3",
                          ""
                        )}
                      >
                        <div
                          className="w-full text-sm pt-2"
                          dangerouslySetInnerHTML={{
                            __html: config.touristDescription,
                          }}
                        ></div>
                      </Tab.Panel>
                      <Tab.Panel
                        key={1}
                        className={classNames("rounded-xl", "")}
                      >
                        {
                          <div className="grid grid-cols-4 gap-4 pb-[100px]">
                            {generalStore.dealsTourist.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="relative animate__animated animate__slideInUp"
                                >
                                  {item.ribbon && (
                                    <div className="absolute z-10 top-[116px] left-[-2px]">
                                      <span
                                        className={`w-full relative text-white text-[10px] text-center tracking-[2px] leading-[20px] inline-block px-2 py-0 rounded-sm ribbon`}
                                        style={{
                                          backgroundColor: item.ribboncolor,
                                        }}
                                      >
                                        <div
                                          className="triangle"
                                          style={{
                                            borderLeftColor: item.ribboncolor,
                                          }}
                                        ></div>
                                        <div className="marquee">
                                          <div className="marquee-wrapper">
                                            <span>{item.ribbon}</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  )}
                                  {/* <div className='absolute left--10 top-[120px] z-10 ribbon text-white text-xs pl-3'>
                                                    {item.ribbon}
                                                </div> */}
                                  <div
                                    key={index}
                                    onClick={() => {
                                      generalStore.deal = item;
                                    }}
                                    className="max-w-sm m-2 h-full bg-white card-tourist rounded-lg shadow relative  flex flex-col"
                                  >
                                    <img
                                      src="default.png"
                                      data-src={
                                        item.thumbnail
                                          ? `${capitalandUrl}/${item.thumbnail}`
                                          : "default.png"
                                      }
                                      onError={(element) => {
                                        element.currentTarget.src =
                                          "default.png";
                                      }}
                                      onLoad={(element) => {
                                        element.currentTarget.src =
                                          element.currentTarget.getAttribute(
                                            "data-src"
                                          );
                                      }}
                                      className="h-[120px] object-cover object-top rounded-t-lg"
                                    />
                                    <div className="flex-grow w-full flex flex-col px-2 justify-between py-3">
                                      <p className="font-medium text-sm line-clamp-2">
                                        {item["jcr:title"]}
                                      </p>
                                      <p className="font-thin text-sm text-gray line-clamp-1 text-capitalize">
                                        {item.source && item.source === "adsign"
                                          ? findPois(item.tenants)
                                          : item._rel_tenants_details[0][
                                              "jcr:title"
                                            ]}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        }
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            ) : (
              <div className="flex flex-col flex-grow w-full pt-[100px] items-center px-5">
                <img
                  src={require("../assets/images/icons/no-information.png")}
                  className="w-[100px] h-[100px] aspect-square mb-5"
                />
                <div className="bg-white bg-opacity-80 backdrop-blur p-4 rounded-xl">
                  <p className="text-lg text-black-primary-100 mb-2 text-center">
                    No Information.
                  </p>
                  <p className="text-sm text-dark-primary-800 text-start">
                    Apologies, the information that you are looking for is not
                    available yet on the application. Alternatively, you can do
                    one of the following options:
                    <ul
                      className="text-sm ml-4 mt-4"
                      style={{ listStyle: "disc" }}
                    >
                      <li>
                        <span className="font-medium">Visit Concierge</span>
                      </li>
                      <li>
                        <span className="font-medium">Check</span> our website
                        for mor information
                      </li>
                      <li>
                        <span className="font-medium">Chat</span> with us online
                      </li>
                      <li>
                        <span className="font-medium">Contact</span> our Call
                        Centre
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DetailDeal />
    </Div100vh>
  );
});

export default TouristDesktop;
