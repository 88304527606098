/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import { HiX, HiLocationMarker } from "react-icons/hi";
import { capitalandS3Url, capitalandUrl } from "../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EmptyState from "./components/EmptyState";
import { Helmet } from "react-helmet";

const DashboardDesktop = observer(() => {
  const { generalStore } = useStores();
  const awmContainerRef = useRef(null);
  const mapState = useSelector((state) => state.map.state);
  const dispatch = useDispatch();
  const [awm, setAwm] = useState(null);
  const [pois, setPois] = useState([]);
  const [query, setQuery] = useState("");
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout); // Clear previous timeout
    const value = event.target.value;
    timeout = setTimeout(() => {
      setQuery(value);
      submitSearch(value);
    }, 300);
  };

  const submitSearch = (q) => {
    let data = generalStore.pois
      .filter(
        (poi) =>
          getCategoryNameById(
            generalStore.sites.filter(
              (site) => site.adsumSiteId == poi.site
            )[0].categories,
            poi.categories[0]
          ) != "Amenities" && getCategoryNameById(
            generalStore.sites.filter(
              (site) => site.adsumSiteId == poi.site
            )[0].categories,
            poi.categories[0]
          ) != "Find My Car"
      )
      .sort((a, b) => {
        return a["name"].localeCompare(b["name"]);
      })
      .filter((e) => {
        return e.name.toString().toLowerCase().indexOf(q.toLowerCase()) === 0;
      });
    setPois(data);
  };

  const initData = () => {
    let data = generalStore.pois
      .filter(
        (poi) =>
          getCategoryNameById(
            generalStore.sites.filter(
              (site) => site.adsumSiteId == poi.site
            )[0].categories,
            poi.categories[0]
          ) != "Amenities" && getCategoryNameById(
            generalStore.sites.filter(
              (site) => site.adsumSiteId == poi.site
            )[0].categories,
            poi.categories[0]
          ) != "Find My Car"
      )
      .sort((a, b) => {
        return a["name"].localeCompare(b["name"]);
      });
      
    setPois(data);
    setTimeout(() => {
      setIsPageLoaded(true)
    }, 300);
  };

  function getCategoryNameById(categories, categoryId) {
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : undefined;
  }

  const navigate = useHistory();
  const [isDisabled, setisDisabled] = useState(false);
  const [description, setdescription] = useState(null);

  return (
    <Div100vh>
      <Helmet>
        <title>CapitaLand Mobile - Mall Selection</title>
      </Helmet>
      {isDisabled && (
        <div
          className="absolute w-screen h-screen z-[200] bg-[#00000042] left-0 top-0 flex items-center justify-center"
          onClick={() => {}}
        >
          <div className="bg-white p-4 flex flex-col max-w-[300px] rounded-lg">
            <img src={require("../assets/images/icons/disabled.png")} />
            <br />
            <center>
              <b>Under Maintenance</b>
            </center>
            {description == null ? (
              <Fragment>
                <p className="text-xs">
                  We apologize for the inconvenience, we hope to be back soon
                </p>
                <p className="text-xs pt-4">
                  Alternatively, you can do one of the following options:
                </p>
                <ul className="text-xs list-disc pl-4">
                  <li>Visit Concierge</li>
                  <li>
                    For enquiries or feedback on CapitaLand Malls, please submit
                    the online enquiry form or contact us at +65 6631 9931 (10am
                    to 6pm daily, including Public Holidays).
                  </li>
                </ul>
              </Fragment>
            ) : (
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            )}
            <br />
            <button
              onClick={() => {
                setisDisabled(false);
              }}
              className="bg-[#008BAF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="w-full h-full flex flex-col">
        <div className="w-full p-2 flex flex-row items-center justify-center bg-white">
          <img
            src={require("../assets/images/logo.png")}
            className="w-32 h-12 object-contain"
            onClick={() => {}}
          />
        </div>
        <div className="w-full h-full bg-splashscreen-desktop-bg bg-cover bg-bottom flex flex-col items-center justify-center">
          <div className="w-9/12 h-full p-4 flex flex-col gap-4 ">
            <div className="flex flex-row items-center gap-3">
              <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none">
                  <div className="grid place-items-center h-full w-12 text-gray-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                </div>
                <input
                  onChange={handleInputChange}
                  className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                  placeholder="Search Mall, Brand or Restaurant..."
                />
              </div>
            </div>
            <div className="w-full h-full overflow-y-scroll">
              {isPageLoaded && query == "" && (
                <div className="grid grid-cols-5 gap-3 pb-[100px]">
                  {generalStore.sites.map((site) => {
                    return (
                      <div
                        onClick={() => {
                          // if (site.config.isDisabledMobile) {
                          //   setisDisabled(true);
                          //   setdescription(site.config.maintenanceDescription);
                          //   return;
                          // }
                          localStorage.setItem("selectedSite", site.name);
                          window.location.href = `/map?mall=${site.name}`;
                        }}
                        className="bg-white rounded-lg p-3 flex flex-col gap-3 items-center justify-between"
                        key={site._id}
                      >
                        <div />
                        <img
                          className="aspect-square object-contain position-center"
                          src="default.png"
                          data-src={`${capitalandS3Url}/media/${site.config.logo}`}
                          onError={(element) => {
                            element.currentTarget.src = "default.png";
                          }}
                          onLoad={(element) => {
                            element.currentTarget.src =
                              element.currentTarget.getAttribute("data-src");
                          }}
                        />
                        <div className="">
                          <p className="text-center text-xs font-medium line-clamp-1">
                            {site.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {isPageLoaded && query != "" && pois.length == 0 && <EmptyState title={"No Result Found"} description={"Sorry, try adjusting your search to find what you are looking for. "} />}
              {isPageLoaded && query != "" && (
                <div className="grid grid-cols-1 gap-2 pb-[100px]">
                  {pois.slice(0, 20).map((tenant) => {
                    return (
                      <div
                        key={tenant.id}
                        className="w-full rounded-lg bg-white flex flex-row relative"
                        onClick={() => {
                          const site = generalStore.sites.find(
                            (site) => site.name == tenant.siteName
                          );

                          // if (site.config.isDisabledMobile) {
                          //   setisDisabled(true);
                          //   setdescription(site.config.maintenanceDescription);
                          //   return;
                          // }
                          
                          localStorage.setItem("selectedSite", tenant.siteName);
                          window.location.href =
                            `/map?mall=${tenant.siteName}` +
                            "&id=" +
                            tenant.signature;
                        }}
                      >
                        <div className="h-full w-16 rounded-l-lg">
                          <img
                            className="h-full aspect-square object-cover rounded-l-lg lazy"
                            src="default.png"
                            data-src={
                              tenant.logo
                                ? `${capitalandUrl}/${tenant.logo}`
                                : "default.png"
                            }
                            onError={(element) => {
                              element.currentTarget.src = "default.png";
                            }}
                            onLoad={(element) => {
                              element.currentTarget.src =
                                element.currentTarget.getAttribute("data-src");
                            }}
                          />
                        </div>
                        <div className="px-4 py-2 flex-grow flex flex-col items-start justify-center">
                          <p className="text-sm line-clamp-1">{tenant.name}</p>
                          <span className="text-xs text-slate-500">
                            {tenant.categories.length > 0 &&
                              getCategoryNameById(
                                generalStore.sites.filter(
                                  (site) =>
                                    site.adsumSiteId == tenant.site
                                )[0].categories,
                                tenant.categories[0]
                              )}
                          </span>
                          <div className="flex flex-row">
                            <HiLocationMarker fontSize={16} color="#008BAF" />
                            <span
                              className="text-xs"
                              style={{ color: "#008BAF" }}
                            >
                              {tenant.siteName}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
});

export default DashboardDesktop;
