/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker, HiX } from "react-icons/hi";
import DetailPoi from "./components/DetailPoi";
import { BottomSheet } from "react-spring-bottom-sheet";
import EmptyState from "./components/EmptyState";

const Directory = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const floors = generalStore.floors;
  const categories = generalStore.selectedSite.categories;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [category, setcategory] = useState([]);
  const [tmpSelectedFloor, setTmpSelectedFloor] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    initData();
  }, []);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout); // Clear previous timeout
    const value = event.target.value;
    timeout = setTimeout(() => {
      setQuery(value);
      submitSearch(value);
    }, 300);
  };

  const submitSearch = (q) => {
    let data = generalStore.pois
      .filter(
        (poi) => !_.map(poi.categoriesDetails, "name").includes("Amenities") && !_.map(poi.categoriesDetails, "name").includes("Find My Car") && poi.signature != null
      )
      .filter((e) => {
        return q == ""
          ? true
          : e.name.toString().toLowerCase().indexOf(q.toLowerCase()) === 0;
      })
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
  };

  const initData = () => {
    let data = generalStore.pois
      .filter(
        (poi) => !_.map(poi.categoriesDetails, "name").includes("Amenities") && !_.map(poi.categoriesDetails, "name").includes("Find My Car") && poi.signature != null
      )
      .filter((poi) => {
        return category.length > 0
          ? poi.categoriesDetails.length > 0
            ? _.map(category).some((j) =>
              _.map(poi.categoriesDetails, "id").includes(j)
            )
            : false
          : true;
      })
      .filter((poi) => {
        if(tmpSelectedFloor.length >0){
          if(poi.locations.length > 0){
            const location = _.map(poi.locations, "name").join().split('-')[0].replace('0',"L");
            
            return tmpSelectedFloor.includes(location);
          }else{
            return false;
          }
        }else{
          return true;
        }
      })
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
  };

  const toggleFloor = (floor) => {
    if (tmpSelectedFloor.includes(floor)) {
      const updatedArray = tmpSelectedFloor.filter((item) => item !== floor);
      setTmpSelectedFloor(updatedArray);
    } else {
      setTmpSelectedFloor([...tmpSelectedFloor, floor]);
    }
  };

  const toggleCategory = (c) => {
    if (category.includes(c)) {
      const updatedArray = category.filter((item) => item !== c);
      setcategory(updatedArray);
    } else {
      setcategory([...category, c]);
    }
  };

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Directory"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full flex-grow flex flex-col relative overflow-hidden">
          <div className="w-full flex flex-row items-center gap-3 p-4">
            <div className="flex-grow relative">
              <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                placeholder="Search..."
                onChange={handleInputChange}
              />
            </div>
            <img
              src={require("../assets/images/icons/filter.png")}
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
          <div className="flex-grow w-full grid grid-cols-1 gap-4 px-4 pb-4 content-start overflow-y-scroll">
            {poiList.length == 0 && <EmptyState title={"No Result Found"} description={"Sorry, try adjusting your search to find what you are looking for. "} />}
            {poiList.map((e) => {
              return (
                <div
                  key={e.group + "group"}
                  className="w-full h-auto flex flex-col gap-2"
                >
                  <span className="font-medium text-white">{e.group}</span>
                  <div className="flex flex-col w-full gap-2">
                    {e.children.map((poi) => {
                      return (
                        <div
                          className="bg-white rounded-lg flex flex-row gap-2"
                          key={poi.id}
                          onClick={() => {
                            generalStore.to = poi;
                          }}
                        >
                          <img
                            className="object-contain w-20 h-20 rounded-s-lg"
                            src="default.png"
                            data-src={
                              poi.logo
                                ? `${capitalandUrl}/${poi.logo}`
                                : "default.png"
                            }
                            onError={(element) => {
                              element.currentTarget.src = "default.png";
                            }}
                            onLoad={(element) => {
                              element.currentTarget.src =
                                element.currentTarget.getAttribute("data-src");
                            }}
                          />
                          <div className="flex-grow flex flex-col items-start justify-center">
                            <h2 className="text-sm font-medium line-clamp-1">
                              {poi.name}
                            </h2>
                            <span className="text-sm font-light text-gray-500 line-clamp-1">
                              {_.map(poi.categoriesDetails, "name").join(", ")}
                            </span>
                            <div className="flex flex-row items-center justify-center w-full">
                              <HiLocationMarker
                                fontSize={16}
                                color={config.primaryColor}
                              />
                              <div className="flex-grow">
                                <span
                                  style={{ color: `${config.primaryColor}` }}
                                  className="text-sm line-clamp-1"
                                >
                                  {poi.locations.length > 0
                                    ? _.map(poi.locations, "name")
                                        .join("-")
                                        .replaceAll("_", " ")
                                    : generalStore.selectedSite.name}
                                </span>
                              </div>
                              {poi.storeStatus &&
                                poi.storeStatus == "new-store" &&
                                config.newstore && (
                                  <div className="flex items-center mr-2">
                                    <img
                                      src={`${capitalandS3Url}/media/${config.newstore}`}
                                      className="h-[15px] mr-[10px]"
                                      alt="newstore"
                                    />
                                  </div>
                                )}

                              {poi.storeStatus &&
                                poi.storeStatus == "opening-soon" &&
                                config.openingsoon && (
                                  <div className="flex items-center mr-2">
                                    <img
                                      src={`${capitalandS3Url}/media/${config.openingsoon}`}
                                      className="h-[15px] mr-[10px]"
                                      alt="openingsoon"
                                    />
                                  </div>
                                )}
                              {poi.storeStatus &&
                                poi.storeStatus == "temporarily-closed" &&
                                config.closed && (
                                  <div className="flex items-center mr-2">
                                    <img
                                      src={`${capitalandS3Url}/media/${config.closed}`}
                                      className="h-[15px] mr-[10px]"
                                      alt="closed"
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DetailPoi />
      <BottomSheet
scrollLocking={false}
        open={isOpen}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1.18]}
        defaultSnap={({ lastSnap, snapPoints }) =>
          lastSnap ?? Math.max(...snapPoints)
        }
        draggable={false}
        header={
          <div className="flex flex-row justify-between">
            <span className=" font-medium">Filter</span>
            <HiX
              fontSize={20}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        }
        className="absolute z-50"
      >
        <div className="flex flex-col-reverse w-full h-full p-3">
          <button
            className="outline outline-red-700 outline-1 font-medium text-red-700 rounded-lg text-sm px-5 py-2.5 mr-2"
            onClick={() => {
              setcategory([]);
              setTmpSelectedFloor([]);
              setOpen(false);
              submitSearch("");
            }}
          >
            Reset Filter
          </button>
          <button
            className="text-white bg-[#008BAF] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            onClick={() => {
              setOpen(false);
              initData();
            }}
          >
            Apply Filter
          </button>
          <div className=" flex flex-col w-full">
            <span className="mb-2 mt-2">Level</span>
            <div className="grid grid-cols-7 gap-2 mb-2">
              {floors.length > 0 &&
                floors
                  .map((m, index) => {
                    return (
                      <div
                        key={index}
                        className={`border aspect-square rounded-lg items-center flex justify-center ${
                          tmpSelectedFloor.includes(m)
                            ? "border-[#008BAF] bg-[#e0f2f6]"
                            : "shadow"
                        }`}
                        onClick={() => {
                          toggleFloor(m);
                        }}
                      >
                        {m}
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="flex-grow w-full flex-col pt-4 overflow-scroll border-b">
            <span className="mb-6">Category</span>
            <div className="w-4 h-4"></div>
            {categories
              .filter(
                (cat) => cat.name !== "Fashion" && cat.name !== "Amenities"
              )
              .map((c, idx) => {
                return (
                  <div className="flex items-center mb-2" key={idx}>
                    <input
                      onChange={() => {
                        toggleCategory(c.id);
                      }}
                      id={`checked-checkbox${idx}`}
                      checked={category.includes(c.id) ? true : false}
                      type="checkbox"
                      className={`w-4 h-4 checked  bg-gray-100 border-gray-300 rounded `}
                    />
                    <label
                      for={`checked-checkbox${idx}`}
                      className={`ml-2 text-sm text-gray-900}`}
                    >
                      {c.name}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      </BottomSheet>
    </Div100vh>
  );
});

export default Directory;
