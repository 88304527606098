/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { capitalandS3Url } from "../utils/constants";
import { Helmet } from "react-helmet";

const Splashscreen = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  return (
    <Div100vh>
      <Helmet>
        <title>
          {generalStore.selectedSite == null ? 'CapitaLand Mobile - Mall Selection' : `CapitaLand - ${generalStore.selectedSite.name} ${generalStore.to ? "- "+ generalStore.to.name : ''}`}
        </title>
      </Helmet>
      {generalStore.allStepLoading > 0 && (
        <div className="w-full h-full bg-splashscreen-bg bg-no-repeat bg-cover bg-bottom flex flex-col">
          <div className="w-full h-[50px]" />
          <div className="flex-grow w-full h-full flex flex-col items-center justify-center">
            <img
              className="h-[120px] w-1/2 object-contain"
              src={require('../assets/images/logo.png')}
              data-src={generalStore.selectedSite == null ? require('../assets/images/logo.png') : `${capitalandS3Url}/media/${generalStore.selectedSite.config.logo}`}
              onError={(element) => {
                element.currentTarget.src = require('../assets/images/logo.png');
              }}
              onLoad={(element) => {
                element.currentTarget.src =
                  element.currentTarget.getAttribute("data-src");
              }}
            />
          </div>
          <div className="w-full flex flex-col items-center justify-center pb-6">
            <div className="w-[200px] h-[6px] bg-white rounded-full">
              <div
                style={{
                  width: `${
                    generalStore.stepLoading == 1
                      ? "0"
                      : (generalStore.stepLoading <= generalStore.allStepLoading ? (Math.ceil(
                        (generalStore.stepLoading /
                          generalStore.allStepLoading) *
                          100
                      )) : 100 )
                  }%`,
                }}
                className={`h-[6px] ${
                  generalStore.stepLoading < generalStore.allStepLoading
                    ? "bg-primary"
                    : "bg-success"
                } rounded-full transition-width transition-all ease`}
              />
            </div>
            <div className="w-2/3 flex flex-row items-center justify-center mt-2 text-SM gap-1">
              {generalStore.stepLoading < generalStore.allStepLoading && (
                <span className="text-primary">
                  {Math.ceil(
                    (generalStore.stepLoading / generalStore.allStepLoading) *
                      100
                  )}
                  %
                </span>
              )}
              <span
                className={
                  generalStore.stepLoading == generalStore.allStepLoading
                    ? "text-black"
                    : "text-black"
                }
              >
                {generalStore.stepLoading == generalStore.allStepLoading
                  ? "DONE!"
                  : "App Loading..."}
              </span>
            </div>
          </div>
        </div>
      )}
    </Div100vh>
  );
});

export default Splashscreen;
