/* eslint-disable */
import { useStores } from "../../use.store";
import React from "react";
import { observer } from "mobx-react-lite";

import { FiClock, FiWifi, IconName } from "react-icons/fi";
const TimeCard = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  return (
    <div className="w-screen flex flex-row justify-center divide-x-2 divide-gray-300 text-gray bg-slate-200 py-4 overflow-hidden relative z-20 pr-5">
      <div className="flex-none flex flex-row px-4 items-center gap-2">
        <FiClock width={200} height={200} fontSize={20} />
        <div className="w-full flex flex-col ">
          <span className="text-[10px]">Opening Hours</span>
          <span className="text-[10px] font-medium">
            {config != null && config.openinghour}
          </span>
        </div>
      </div>
      {config != null && config.wifi != " " && (
        <div className="flex-none flex flex-row px-4 items-center gap-2">
          <div className="flex flex-row flex-grow">
            <FiWifi width={200} height={200} fontSize={20} />
            <div className="flex-grow flex flex-col  ml-2">
              <span className="text-[10px]">Network ID</span>
              <div className="marquee">
                <div className="marquee-wrapper text-[10px]">
                  <span>{config != null && config.wifi}</span>
                </div>
              </div>
            </div>
          </div>
          {config != null && config.wifipassword != null && (
            <div className="flex-grow flex flex-col ml-2">
              <span className="text-[10px]">Password</span>
              <div className="marquee">
                <div className="marquee-wrapper text-[10px]">
                  <span>{config != null && config.wifipassword}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default TimeCard;
