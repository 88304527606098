/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker, HiX } from "react-icons/hi";
import DetailPoi from "./components/DetailPoi";
import { BottomSheet } from "react-spring-bottom-sheet";
import SidebarContainer from "./components/SideBarContainer";
import EmptyState from "./components/EmptyState";

const DineDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [category, setcategory] = useState([]);
  const [cuisine, setcuisine] = useState([]);
  const [foodType, setfoodType] = useState([]);
  const [vibe, setvibe] = useState([]);
  const [categories, setcategories] = useState([]);
  const [cuisines, setcuisines] = useState([]);
  const [foodTypes, setfoodTypes] = useState([]);
  const [vibes, setvibes] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout); // Clear previous timeout
    const value = event.target.value;
    timeout = setTimeout(() => {
      setQuery(value);
      submitSearch(value);
    }, 300);
  };

  const submitSearch = (q) => {
    let data = generalStore.pois
      .filter((poi) =>
        _.map(poi.categoriesDetails, "name").includes("Food & Beverage")
      )
      .filter((e) => {
        return q == ""
          ? true
          : e.name.toString().toLowerCase().indexOf(q.toLowerCase()) === 0;
      })
      .filter((poi) => {
        if (category.length > 0) {
          return poi.subCategories.some((r) => category.indexOf(r) >= 0);
        } else {
          return true;
        }
      })
      .filter((poi) => {
        if (cuisine.length > 0) {
          return poi.cuisines.some((r) => cuisine.indexOf(r) >= 0);
        } else {
          return true;
        }
      })
      .filter((poi) => {
        if (foodType.length > 0) {
          return poi.foodTypes.some((r) => foodType.indexOf(r) >= 0);
        } else {
          return true;
        }
      })
      .filter((poi) => {
        if (vibe.length > 0) {
          return poi.vibes.some((r) => vibe.indexOf(r) >= 0);
        } else {
          return true;
        }
      });
    setPoiList(Object.values(data));
  };

  const initData = () => {
    let data = generalStore.pois.filter((poi) =>
      _.map(poi.categoriesDetails, "name").includes("Food & Beverage")
    );

    const poisDine = generalStore.pois.filter((poi) =>
      _.map(poi.categoriesDetails, "name").includes("Food & Beverage")
    );
    const categories = _.sortBy(
      _.uniq(_.flatten(_.map(poisDine, "subCategories")))
    );
    const cuisines = _.sortBy(_.uniq(_.flatten(_.map(poisDine, "cuisines"))));
    const foodTypes = _.sortBy(_.uniq(_.flatten(_.map(poisDine, "foodTypes"))));
    const vibes = _.sortBy(_.uniq(_.flatten(_.map(poisDine, "vibes"))));

    setcategories(categories);
    setcuisines(cuisines);
    setfoodTypes(foodTypes);
    setvibes(vibes);
    setPoiList(Object.values(data));
  };

  const toggleCategory = (c, type) => {
    if (type == "Subcategory") {
      if (category.includes(c)) {
        const updatedArray = category.filter((item) => item !== c);
        setcategory(updatedArray);
      } else {
        setcategory([...category, c]);
      }
    } else if (type == "Cuisines") {
      if (cuisine.includes(c)) {
        const updatedArray = cuisine.filter((item) => item !== c);
        setcuisine(updatedArray);
      } else {
        setcuisine([...cuisine, c]);
      }
    } else if (type == "Food Type") {
      if (foodType.includes(c)) {
        const updatedArray = foodType.filter((item) => item !== c);
        setfoodType(updatedArray);
      } else {
        setfoodType([...foodType, c]);
      }
    } else if (type == "Vibes") {
      if (vibe.includes(c)) {
        const updatedArray = vibe.filter((item) => item !== c);
        setvibe(updatedArray);
      } else {
        setvibe([...vibe, c]);
      }
    }
  };

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Dine"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full flex-grow flex flex-col relative overflow-hidden">
          <div className="w-full flex flex-row items-center gap-3 p-4">
            <div className="flex-grow relative">
              <div className="absolute inset-y-0 left-0 flex items-center  pointer-events-none">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                placeholder="Search..."
                onChange={handleInputChange}
              />
            </div>
            <img
              src={require("../assets/images/icons/filter.png")}
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
          {poiList.length == 0 && <EmptyState title={"No Result Found"} description={"Sorry, try adjusting your search to find what you are looking for. "} />}
          <div className="flex-grow w-full grid grid-cols-5 gap-4 px-4 pb-4 content-start overflow-y-scroll">
            {poiList.map((poi) => {
              return (
                <div
                  className="bg-white rounded-lg flex flex-row gap-2"
                  key={poi.id}
                  onClick={() => {
                    generalStore.to = poi;
                    navigate.push('floor-guide');
                  }}
                >
                  <img
                    className="object-cover w-20 h-20 rounded-s-lg"
                    src="default.png"
                    data-src={
                      poi.logo ? `${capitalandUrl}/${poi.logo}` : "default.png"
                    }
                    onError={(element) => {
                      element.currentTarget.src = "default.png";
                    }}
                    onLoad={(element) => {
                      element.currentTarget.src =
                        element.currentTarget.getAttribute("data-src");
                    }}
                  />
                  <div className="flex-grow flex flex-col items-start justify-center">
                    <h2 className="text-sm font-medium line-clamp-1">
                      {poi.name}
                    </h2>
                    <span className="text-sm font-light text-gray-500 line-clamp-1">
                      {_.map(poi.categoriesDetails, "name").join(", ")}
                    </span>
                    <div className="flex flex-row items-center justify-center w-full">
                      <HiLocationMarker
                        fontSize={16}
                        color={config.primaryColor}
                      />
                      <div className="flex-grow">
                        <span
                          style={{ color: `${config.primaryColor}` }}
                          className="text-sm line-clamp-1"
                        >
                          {poi.locations.length > 0
                            ? _.map(poi.locations, "name")
                                .join("-")
                                .replaceAll("_", " ")
                            : generalStore.selectedSite.name}
                        </span>
                      </div>
                      {poi.storeStatus &&
                        poi.storeStatus == "new-store" &&
                        config.newstore && (
                          <div className="flex items-center mr-2">
                            <img
                              src={`${capitalandS3Url}/media/${config.newstore}`}
                              className="h-[15px] mr-[10px]"
                              alt="newstore"
                            />
                          </div>
                        )}

                      {poi.storeStatus &&
                        poi.storeStatus == "opening-soon" &&
                        config.openingsoon && (
                          <div className="flex items-center mr-2">
                            <img
                              src={`${capitalandS3Url}/media/${config.openingsoon}`}
                              className="h-[15px] mr-[10px]"
                              alt="openingsoon"
                            />
                          </div>
                        )}
                      {poi.storeStatus &&
                        poi.storeStatus == "temporarily-closed" &&
                        config.closed && (
                          <div className="flex items-center mr-2">
                            <img
                              src={`${capitalandS3Url}/media/${config.closed}`}
                              className="h-[15px] mr-[10px]"
                              alt="closed"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className={`h-screen w-screen bg-[#00000057] fixed right-0 top-0 z-50 flex flex-col`}
        >
          <div className="h-screen absolute right-0 bg-white flex flex-col p-3 w-1/4">
            <div className="flex flex-row justify-between">
              <span className=" font-medium">Filter</span>
              <HiX
                fontSize={20}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className="flex flex-col-reverse w-full h-full relative">
              <div className="py-4 flex flex-col">
              <button
                className="outline outline-red-700 outline-1 font-medium text-red-700 rounded-lg text-sm px-5 py-2.5 mb-2 mx-2"
                onClick={() => {
                  setcategory([]);
                  setcuisine([]);
                  setvibe([]);
                  setfoodType([]);
                  setOpen(false);
                  initData();
                }}
              >
                Reset Filter
              </button>
              <button
                className="text-white bg-[#008BAF] font-medium rounded-lg text-sm px-5 py-2.5 mx-2 mb-2"
                onClick={() => {
                  setOpen(false);
                  submitSearch(query);
                }}
              >
                Apply Filter
              </button>
              </div>
              <div className="w-full h-full flex-col overflow-y-scroll border-b p-4">
                <span className="mb-3 text-sm font-medium">Subcategory</span>
                <div className="my-2">
                  {categories.map((c, idx) => {
                    return (
                      <div className="flex items-center mb-2" key={idx}>
                        <input
                          onChange={() => {
                            toggleCategory(c, "Subcategory");
                          }}
                          id={`category${idx}`}
                          checked={category.includes(c) ? true : false}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          for={`category${idx}`}
                          className="ml-2 text-sm text-gray-900"
                        >
                          {c}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <span className="mb-3 text-sm font-medium">Cuisines</span>
                <div className="my-2">
                  {cuisines.map((c, idx) => {
                    return (
                      <div className="flex items-center mb-2" key={idx}>
                        <input
                          onChange={() => {
                            toggleCategory(c, "Cuisines");
                          }}
                          id={`cuisine${idx}`}
                          checked={cuisine.includes(c) ? true : false}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          for={`cuisine${idx}`}
                          className="ml-2 text-sm text-gray-900"
                        >
                          {c}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <span className="mb-3 text-sm font-medium">Food Type</span>
                <div className="my-2">
                  {foodTypes.map((c, idx) => {
                    return (
                      <div className="flex items-center mb-2" key={idx}>
                        <input
                          onChange={() => {
                            toggleCategory(c, "Food Type");
                          }}
                          id={`foodtype${idx}`}
                          checked={foodType.includes(c) ? true : false}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          for={`foodtype${idx}`}
                          className="ml-2 text-sm text-gray-900"
                        >
                          {c}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <span className="mb-3 text-sm font-medium">Vibes</span>
                <div className="my-2">
                  {vibes.map((c, idx) => {
                    return (
                      <div className="flex items-center mb-2" key={idx}>
                        <input
                          onChange={() => {
                            toggleCategory(c, "Vibes");
                          }}
                          id={`vibe${idx}`}
                          checked={vibe.includes(c) ? true : false}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          for={`vibe${idx}`}
                          className="ml-2 text-sm text-gray-900"
                        >
                          {c}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Div100vh>
  );
});

export default DineDesktop;
