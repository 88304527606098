/* eslint-disable */
import { makeObservable, observable } from "mobx";
import _ from "lodash";
import { DistCacheManager, EntityManager } from "@adactive/adsum-client-api";
import ACA from "@adactive/adsum-utils/services/ClientAPI";
import { email, password, selectedMall } from "../utils/const";
import axios from "axios";
import { baseUrl } from "../utils/constants";
export class GeneralStore {
  loading = true;
  deviceConfig = null;
  entityManager = null;
  activePage = "main";
  pois = [];
  allPois = [];
  awm = null;
  aca = null;
  allStepLoading = 1;
  stepLoading = 1;
  searchAction = "";
  from = null;
  historyFrom = [];
  to = null;
  historyTo = [];
  allCategories = [];
  floors = [];
  allFloors = [];
  activeFloor = null;
  tmtType = "";
  isWayfindingRunning = false;
  isFromDirectory = false;
  whatsOns = [];
  sites = [];
  tenants = [];
  selectedSite = null;
  openFloorGuide = false;
  mapLoaded = false;
  deals = [];
  dealsTourist = [];
  events = [];
  media = null;
  deal = null;
  multipleTo = [];
  hidePoi = [187054, 187053];
  nonRoutePoi = [187054, 187053];
  amenitiesPois = []

  constructor() {
    makeObservable(this, {
      loading: observable,
      activePage: observable,
      pois: observable,
      awm: observable,
      aca: observable,
      stepLoading: observable,
      searchAction: observable,
      from: observable,
      historyFrom: observable,
      to: observable,
      historyTo: observable,
      allCategories: observable,
      floors: observable,
      tmtType: observable,
      activeFloor: observable,
      isFromDirectory: observable,
      whatsOns: observable,
      sites: observable,
      allStepLoading: observable,
      tenants: observable,
      selectedSite: observable,
      openFloorGuide: observable,
      mapLoaded: observable,
      allPois: observable,
      deals: observable,
      dealsTourist: observable,
      events: observable,
      media: observable,
      deal: observable,
      allFloors: observable,
      multipleTo: observable,
      amenitiesPois : observable
    });
    this.getAppData();
  }

  getAppData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const siteParam =
      urlParams.get("mall") ?? localStorage.getItem("selectedSite");
    const idParam = urlParams.get("id");
    
    if(!siteParam && window.location.pathname != '/'){
      window.location.href='/';
    }
    this.allStepLoading = 6 + (!siteParam ? 15 : 1);
    this.stepLoading = this.stepLoading + 1;
    const responseToken = await axios.post(`${baseUrl}/auth/signin`, {
      email: email,
      password: password,
    });

    const token = responseToken.data.accessToken;
    const response = await axios.get(`${baseUrl}/site`, {
      headers: {
        Authorization: "Bearer " + token,
        ContentType: "application/json",
      },
    });

    this.stepLoading = this.stepLoading + 1;

    const sites = await Promise.all(
      response.data.data
        .filter((item) => {
          if (siteParam) {
            if (siteParam == "Bugis ") {
              return item.name == "Bugis+";
            } else {
              return item.name == siteParam;
            }
          } else {
            return item.organizationName == "Capitaland";
          }
        })
        .map(async (item) => {
          const config = await axios.get(
            `${baseUrl}/configuration?siteId=${item._id}`,
            {
              headers: {
                Authorization: "Bearer " + token,
                ContentType: "application/json",
              },
            }
          );
          item.config = config.data.data;

          if (item.config != null && !item.config.isDisabledMobile) {
            const siteId = item._id;
            const adsumSiteId = item.adsumSiteId;

            if (siteParam) {
              this.selectedSite = item;
              const [
                events,
                medias,
                deals,
                tourists,
                tenants,
                pois,
                categories,
              ] = await Promise.all([
                axios
                  .post(
                    `${baseUrl}/event/filter`,
                    { siteId, type: "published" },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => res.data.data)
                  .catch((err) => err.response.data),

                axios
                  .post(
                    `${baseUrl}/media/list`,
                    { siteId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => res.data.data)
                  .catch((err) => err.response.data),

                axios
                  .post(
                    `${baseUrl}/deal/filter`,
                    { siteId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => res.data.data)
                  .catch((err) => err.response.data),

                axios
                  .post(
                    `${baseUrl}/deal/tourist`,
                    { siteId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => res.data.data)
                  .catch((err) => err.response.data),

                axios
                  .post(
                    `${baseUrl}/tenant/filter`,
                    { siteId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => res.data.tenant)
                  .catch((err) => err.response.data),

                axios
                  .get(`${baseUrl}/adsum/get/poi/${adsumSiteId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => res.data)
                  .catch((err) => err.response.data),

                axios
                  .get(`${baseUrl}/adsum/get/category/${adsumSiteId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => res.data)
                  .catch((err) => err.response.data),
              ]);

              pois.map((poi) => {
                const poiDesc = JSON.parse(poi.description);
                poi['signature'] = poiDesc && poiDesc.nodename ? poiDesc.nodename :  poi.signature;
                poi["siteName"] = item.name;
                poi["siteId"] = item._id;
                poi["logo"] = poiDesc && poiDesc.logo ? poiDesc.logo : null;
                poi["locations"] =
                  poiDesc && poiDesc.locations ? poiDesc.locations : [];
                poi["adsumSiteId"] = item.adsumSiteId;
              });
              item.pois = pois;
              item.categories = categories;
              item.tenants = tenants;
              item.deals = deals;
              item.events = events;
              item.tourists = tourists;
              item.medias = medias;
            } else {
              const [pois, categories] = await Promise.all([
                axios
                  .get(`${baseUrl}/adsum/get/poi/${adsumSiteId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => res.data)
                  .catch((err) => err.response.data),
                axios
                  .get(`${baseUrl}/adsum/get/category/${adsumSiteId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => res.data)
                  .catch((err) => err.response.data),
              ]);

              pois.map((poi) => {
                const poiDesc = JSON.parse(poi.description);
                poi['signature'] = poiDesc && poiDesc.nodename ? poiDesc.nodename :  poi.signature;
                poi["siteName"] = item.name;
                poi["siteId"] = item._id;
                poi["logo"] = poiDesc && poiDesc.logo ? poiDesc.logo : null;
                poi["locations"] =
                  poiDesc && poiDesc.locations ? poiDesc.locations : [];
                poi["adsumSiteId"] = item.adsumSiteId;
              });
              item.pois = pois;
              item.categories = categories;
            }
          }
          this.stepLoading = this.stepLoading + 1;
          return item;
        })
    );

    this.stepLoading = this.stepLoading + 1;

    let allPois = sites.reduce((acc, site) => acc.concat(site.pois || []), []);
    allPois = allPois
      .filter(
        (poi) =>
          !this.hidePoi.includes(poi.id) && !this.nonRoutePoi.includes(poi.id) && poi.signature != null
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    sites.sort((a, b) => a.name.localeCompare(b.name));

    this.sites = sites.filter((site)=>site.config!=null && !site.config.isDisabledMobile);
    this.pois = allPois;
    this.initData();
  };

  initData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const siteParam =
      urlParams.get("mall") ?? localStorage.getItem("selectedSite");
    const idParam = urlParams.get("id");

    if (!siteParam) {
      this.stepLoading = this.stepLoading + 1;
      this.stepLoading = this.stepLoading + 1;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    } else {
      this.selectedSite = this.sites[0];
      this.stepLoading = this.stepLoading + 1;
      const tenants = this.selectedSite.tenants;
      tenants.map((tenant) => {
        tenant["siteName"] = this.selectedSite.name;
        tenant["siteId"] = this.selectedSite._id;
        tenant["adsumSiteId"] = this.selectedSite.adsumSiteId;
        tenant["name"] = tenant["jcr:title"];
        tenant["logo"] =
          tenant.thumbnail_details && tenant.thumbnail_details.length > 0
            ? tenant.thumbnail_details[0].entityname
            : "default.png";
      });
      this.tenants.push(tenants);
      this.deals = this.selectedSite.deals;
      this.dealsTourist = this.selectedSite.tourists;
      this.events = this.selectedSite.events;

      const siteConfig = await selectedMall(this.selectedSite["adsumSiteId"]);
      const entityManager = new EntityManager(
        Object.assign({}, siteConfig.config.api, {
          cacheManager: new DistCacheManager("/local"),
        })
      );
      await entityManager.load();
      ACA.setEntityManager(entityManager);
      this.aca = ACA;
      const allPois = ACA.getAllPois();
      const allCategories = ACA.getAllCategories();
      const findAmenities = allCategories.find(
        (cat) => cat.name === "Amenities"
      );
      const filteredPois = allPois.map((poi) => {
        const locations = ACA.getPlaces(
          _.map(Array.from(poi.places.values), "value")
        );

        const categoriesDetails = ACA.getCategories(
          _.map(Array.from(poi.categories.values), "value")
        );

        let poiDesc;
        try {
          poiDesc = JSON.parse(poi.description);
        } catch (e) {
          poiDesc = { description: poi.description };
        }

        const storeStatus =
          poiDesc && poiDesc.storeStatus ? poiDesc.storeStatus : null;
        const storeStatusSplit = storeStatus ? storeStatus.split("/") : null;
        const storeStatusName = storeStatusSplit
          ? storeStatusSplit[storeStatusSplit.length - 1]
          : null;
        const allPlaces = Array.from(poi.places.values).map((place) =>
          ACA.getPlace(place.value)
        );
        const shape_id = allPlaces.length > 0 && allPlaces[0] ? allPlaces[0].shape_id : null;

        const poiAEM = this.selectedSite.tenants.find(
          (tenant) => tenant["jcr:title"] === poi.name
        );
        return {
          ...poi,
          name: poi.name.charAt(0).toUpperCase() + poi.name.slice(1),
          description:
            poiDesc && poiDesc.description
              ? poiDesc.description
              : poiDesc && poiDesc.shortdescription
              ? poiDesc.shortdescription
              : null,
              signature  : poiDesc && poiDesc.nodename ? poiDesc.nodename :  poi.signature,
          url: poiDesc && poiDesc.url ? poiDesc.url : null,
          chopelink: poiDesc && poiDesc.chopelink ? poiDesc.chopelink : null,
          opentime: poiDesc && poiDesc.opentime ? poiDesc.opentime : null,
          locations: poiDesc && poiDesc.locations ? poiDesc.locations : null,
          images: poiDesc && poiDesc.images ? poiDesc.images : null,
          logo: poiDesc && poiDesc.logo ? poiDesc.logo : null,
          phone: poiDesc && poiDesc.phone ? poiDesc.phone : null,
          subCategories:
            poiDesc && poiDesc.subCategories ? poiDesc.subCategories : null,
          cuisines: poiDesc && poiDesc.cuisines ? poiDesc.cuisines : null,
          foodTypes: poiDesc && poiDesc.foodTypes ? poiDesc.foodTypes : null,
          acceptseCapitavoucher:
            poiDesc && poiDesc.acceptseCapitavoucher
              ? poiDesc.acceptseCapitavoucher
              : null,
          acceptsDbsInstantEarn:
            poiDesc && poiDesc.acceptsDbsInstantEarn
              ? poiDesc.acceptsDbsInstantEarn
              : null,
          acceptsCapitavoucher:
            poiDesc && poiDesc.acceptsCapitavoucher
              ? poiDesc.acceptsCapitavoucher
              : null,
          acceptsHalalCertified:
            poiDesc && poiDesc.acceptsHalalCertified
              ? poiDesc.acceptsHalalCertified
              : null,
          acceptsCapitacard:
            poiDesc && poiDesc.acceptsCapitacard
              ? poiDesc.acceptsCapitacard
              : null,
          vibes: poiDesc && poiDesc.vibes ? poiDesc.vibes : null,
          tags: poiAEM ? poiAEM["cq:tags"] : [],
          poiDesc: poiDesc,
          locations,
          categoriesDetails,
          shape_id,
          storeStatus: storeStatusName,
        };
      });
      this.pois = filteredPois.filter((poi) => {
        return (
          !this.hidePoi.includes(poi.id) && !this.nonRoutePoi.includes(poi.id) && poi.signature != null
        );
      });
      this.pois = this.pois.sort((a, b) => {
        return a["name"].localeCompare(b["name"]);
      });
      const categories = _.sortBy(allCategories, "name");
      const promotionMedias = ACA.getMediasByPlaylistTag("Promotion");
      const eventsMedias = ACA.getMediasByPlaylistTag("Events");

      const amenitiesPois = _.sortBy(
        filteredPois.filter((poi) => {
          if (findAmenities != null) {
            return (
              _.map(this.tenants, "jcr:title").includes(poi.name) ||
              _.map(poi.categoriesDetails, "id").includes(findAmenities.id)
            );
          } else {
            return _.map(this.tenants, "jcr:title").includes(poi.name);
          }
        }),
        [(poi) => poi.name.toLowerCase(), (poi) => _.map(poi.locations, "name")]
      );

      this.allPois = amenitiesPois;
      this.amenitiesPois = amenitiesPois

      filteredPois
        .filter((poi) => {
          return !_.map(poi.categoriesDetails, "id").includes(findAmenities.id);
        })
        .map((poi) => {
          poi.locations
            .filter((location) => {
              return location.name != "Space";
            })
            .map((location) => {
              this.floors.push(location.name.split("-")[0].replace("0", "L"));
            });
        });
      this.floors = this.floors.filter(
        (v, i, a) => a.findIndex((v2) => v2 === v) === i
      );
      this.floors = this.floors.sort((a, b) => {
        let letterA = a[0];
        let letterB = b[0];
        let numberA = parseInt(a.substring(1));
        let numberB = parseInt(b.substring(1));

        if (letterA !== letterB) {
          return letterA.localeCompare(letterB);
        } else {
          return numberA - numberB;
        }
      });

      if (idParam != null) {
        const selectedPoi = this.pois.filter((poi) => poi.signature == idParam);
        if (selectedPoi.length > 0) {
          this.to = selectedPoi[0];
        }
      }
      this.stepLoading = this.stepLoading + 1;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  };
}