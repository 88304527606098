/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import Lottie from "react-lottie";
import { capitalandS3Url } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { observer } from "mobx-react-lite";
import { useStores } from "../../use.store";

const MenuCard = observer(({ item, config }) => {
  const navigate = useHistory();
  const { generalStore } = useStores();

  return (
    <div
      className="w-full h-full bg-white relative rounded-lg shadow"
      onClick={() => {
        var name = item["name"].replace("_", "-");
        if (name == "directory") {
          name = "directory";
        } else if (name == "promotions") {
          name = "promotion";
        } else if (name == "floor-guide") {
          name = "floor-guide";
        } else if (name == "tourist-programme") {
          name = "tourist";
        }else if(name == "concierge"){
          name = "mall-info"
        }
        // generalStore.activePage = name;
        navigate.push(name);
      }}
    >
      {config[item["bg"]] ? (
        config[item["bg"]].split(".")[
          config[item["bg"]].split(".").length - 1
        ] === "mp4" ? (
          <video
            autoPlay
            muted
            loop
            controls={false}
            playsInline={true}
            className="w-full h-full object-cover absolute inset-0 rounded-lg"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              background: `url('${require("../../assets/images/icons/img-default.png")}')`,
              backgroundSize: "cover",
            }}
          >
            <source
              src={`${capitalandS3Url}/media/${config[item["bg"]]}`}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            loader={
              <img
                className="w-full h-full object-cover absolute inset-0 rounded-lg"
                src={require(`../../assets/images/icons/img-default.png`)}
              />
            }
            src={`${capitalandS3Url}/media/${config[item["bg"]]}`}
            className="w-full h-full object-cover absolute inset-0 rounded-lg"
          />
        )
      ) : (
        <img
          src={require(`../../assets/images/icons/img-default.png`)}
          className="w-full h-full object-cover absolute inset-0 rounded-lg"
        />
      )}
      <div
        className="w-full h-full absolute inset-0 rounded-lg"
        style={{ backgroundColor: "#00000059" }}
      ></div>
      <div className="w-full h-full top-0 flex flex-col absolute  items-center justify-center ">
        <div
          style={{
            height: "32px",
            width: "32px",
            borderRadius: "32px",
            backgroundColor: "#ffffff57",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: require(`../../assets/images/animation/${item["name"]}.json`),
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>
        <span
          className="text-white font-semibold mt-2 px-4 text-center uppercase line-clamp-2"
          style={{
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "15px",
            letterSpacing: "0.2em",
          }}
        >
          {item["name"] == "concierge"
            ? "Mall Info"
            : item["name"].replace("_", " ")}
        </span>
      </div>
    </div>
  );
});
// function MenuCard({ item, config }) {
//   const navigate = useHistory();

//   return (
//     <div
//       className="w-full h-full bg-white relative rounded-lg shadow"
//       onClick={() => {
//         var name = item["name"].replace("_", "-");
//         if (name == "directory") {
//           name = "stores";
//         } else if (name == "promotions") {
//           name = "promotion";
//         } else if (name == "floor-guide") {
//           name = "floor-guide";
//         } else if (name == "tourist-programme") {
//           name = "tourist";
//         }
//         if (name != "floor-guide") {
//           navigate.push(name);
//         }
//       }}
//     >
//       {config[item["bg"]] ? (
//         config[item["bg"]].split(".")[
//           config[item["bg"]].split(".").length - 1
//         ] === "mp4" ? (
//           <video
//             autoPlay
//             muted
//             loop
//             controls={false}
//             playsInline={true}
//             className="w-full h-full object-cover absolute inset-0 rounded-lg"
//             onClick={(e) => {
//               e.preventDefault();
//             }}
//             style={{
//               background: `url('${require("../../assets/images/icons/img-default.png")}')`,
//               backgroundSize: "cover",
//             }}
//           >
//             <source
//               src={`${capitalandS3Url}/media/${config[item["bg"]]}`}
//               type="video/mp4"
//             />
//           </video>
//         ) : (
//           <img
//             loader={
//               <img
//                 className="w-full h-full object-cover absolute inset-0 rounded-lg"
//                 src={require(`../../assets/images/icons/img-default.png`)}
//               />
//             }
//             src={`${capitalandS3Url}/media/${config[item["bg"]]}`}
//             className="w-full h-full object-cover absolute inset-0 rounded-lg"
//           />
//         )
//       ) : (
//         <img
//           src={require(`../../assets/images/icons/img-default.png`)}
//           className="w-full h-full object-cover absolute inset-0 rounded-lg"
//         />
//       )}
//       <div
//         className="w-full h-full absolute inset-0 rounded-lg"
//         style={{ backgroundColor: "#00000059" }}
//       ></div>
//       <div className="w-full h-full top-0 flex flex-col absolute  items-center justify-center ">
//         <div
//           style={{
//             height: "32px",
//             width: "32px",
//             borderRadius: "32px",
//             backgroundColor: "#ffffff57",
//           }}
//         >
//           <Lottie
//             options={{
//               loop: true,
//               autoplay: true,
//               animationData: require(`../../assets/images/animation/${item["name"]}.json`),
//               rendererSettings: {
//                 preserveAspectRatio: "xMidYMid slice",
//               },
//             }}
//             style={{ flex: 1, pointerEvents: "none" }}
//           />
//         </div>
//         <span
//           className="text-white font-semibold mt-2 px-4 text-center uppercase line-clamp-2"
//           style={{
//             fontSize: "12px",
//             fontWeight: "700",
//             lineHeight: "15px",
//             letterSpacing: "0.2em",
//           }}
//         >
//           {item["name"] == "concierge"
//             ? "Mall Info"
//             : item["name"].replace("_", " ")}
//         </span>
//       </div>
//     </div>
//   );
//   // return <div key={item['name']} onClick={() => {
//   //     if (item['name'].replace('_', ' ').toLocaleLowerCase() == 'floor guide') {
//   //         if ((!configLoaded)) {
//   //             toast('Data is not loaded yet!', {
//   //                 position: "top-right",
//   //                 autoClose: 2000,
//   //                 hideProgressBar: true,
//   //                 closeOnClick: true,
//   //                 pauseOnHover: true,
//   //                 draggable: true,
//   //                 progress: undefined,
//   //                 theme: "dark",
//   //                 onClose: () => {

//   //                 }
//   //             });
//   //             return;
//   //         } else {
//   //             dispatch(setActivePage(item['name'].replace('_', '-')));
//   //         }
//   //     } else {
//   //         dispatch(setActivePage(item['name'].replace('_', '-')));
//   //     }

//   // }} className={`w-full h-full bg-white rounded-lg shadow relative`}>
//   //     {config[item['bg']] ? (
//   //         config[item['bg']].split(".")[
//   //             config[item['bg']].split(".").length - 1
//   //         ] === "mp4" ? (
//   //             <video
//   //                 autoPlay
//   //                 playsInline
//   //                 muted
//   //                 loop
//   //                 className="rounded-lg object-cover"
//   //             >
//   //                 <source
//   //                     src={`${capitalandS3Url}/media/${config[item['bg']]}`}
//   //                     type="video/mp4"
//   //                 />
//   //             </video>
//   //         ) : (
//   //             <img
//   //                 src={`${capitalandS3Url}/media/${config[item['bg']]}`}
//   //                 className="rounded-lg object-cover"
//   //             />
//   //         )
//   //     ) : null}
//   //     {/* <img className="w-full rounded-lg object-cover" src={require('../../assets/images/cards/' + item + '.png')} alt="" /> */}
//   //     <div className='w-full h-full top-0 flex flex-col absolute  items-center justify-center'>
//   //         <img src={require('../../assets/images/cards/' + item['name'] + '_icon.png')} />
//   //         <span className='text-white font-semibold mt-2 px-4 text-center uppercase line-clamp-2' style={{ fontSize: '12px', fontWeight: '700', lineHeight: '15px', letterSpacing: '0.2em' }}>{item['name'].replace('_', ' ')}</span>
//   //     </div>
//   // </div>
// }

export default MenuCard;
