/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiArrowRight, HiLocationMarker } from "react-icons/hi";
import DetailPoi from "./components/DetailPoi";
import DetailMedia from "./components/DetailMedia";

const HappeningDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const events = generalStore.events;
  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Happenings"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full flex-grow flex flex-col relative overflow-hidden">
          <div className="flex-grow w-full grid grid-cols-6 gap-4 px-4 py-4 content-start overflow-y-scroll">
            {events.map((event) => {
              return (
                <div
                  key={event.id + "group"}
                  onClick={() => {
                    generalStore.media = event;
                  }}
                  className="w-full h-[calc(100vw/2 + 50)] flex flex-col gap-2  bg-white rounded-lg relative"
                >
                  <img
                    className="object-cover w-full aspect-square rounded-t-lg"
                    src="default.png"
                    data-src={
                      event.thumbnail
                        ? `${capitalandUrl}/${event.thumbnail}`
                        : "default.png"
                    }
                    onError={(element) => {
                      element.currentTarget.src = "default.png";
                    }}
                    onLoad={(element) => {
                      element.currentTarget.src =
                        element.currentTarget.getAttribute("data-src");
                    }}
                  />
                  <div className="flex-grow w-full flex flex-col gap-2 px-2 justify-between pb-2">
                    <h2 className="font-medium text-sm line-clamp-2">
                      {event["jcr:title"]}
                    </h2>
                    <div className="flex flex-col w-full">
                      <div
                        className="flex flex-row items-center text-sm"
                        style={{ color: config.primaryColor }}
                      >
                        <HiLocationMarker />
                        <span className="ml-1 text-sm line-clamp-1">
                          {event &&
                          event._rel_properties_details &&
                          event._rel_properties_details.length > 0 &&
                          event._rel_properties_details.find(
                            (x) => x.entityname === config.propertiesName
                          )
                            ? event._rel_properties_details.find(
                                (x) => x.entityname === config.propertiesName
                              )["jcr:title"]
                            : generalStore.selectedSite.name}
                        </span>
                      </div>
                      <div
                        className="flex flex-row items-center text-sm"
                        style={{
                          color:
                            config.secondaryColor,
                        }}
                      >
                        <span className="ml-1 text-sm">EVENT DETAIL</span>
                        <HiArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DetailMedia/>
    </Div100vh>
  );
});

export default HappeningDesktop;
