const initialState = {
  mapLoaded: false,
  percentage: 0,
  awm: null,
  isLoading: true,
  configLoaded: false,
  mapType: null,
  activePage: "dashboard",
  isKeyboardShow: false,
  searchFromHistory: [],
  searchToHistory: [],
  pois: [],
  indexedPoi: [],
  allPois: [],
  allCategories: [],
  floors: [],
  keyword: "",
  categories: [],
  isDetailShow: false,
  selectedPoi: null,
  selectedMultiplePois: [],
  selectedSpace: null,
  categoryToggle: false,
  levelToggle: false,
  keyboardType: "alpha",
  descriptionToggle: false,
  medias: [],
  poiDetailPageShow: false,
  poiDetailMultiplePageShow: false,
  isActionEnabled: true,
  mediaPage: {
    current: 0,
    perPage: 4,
  },
  selectedMedia: null,
  routing: false,
  routingStart: false,
  isAmenitiesOpen: false,
  screensavers: [],
  isScreensaversShow: false,
  screensaverTimeout: null,
  isToiletOpen: false,
  amenitiesActive: null,
  allAmenities: [],
  isMainOpen: false,
  selectedCategories: [],
  selectedLevels: [],
  poiType: "Tenants",
  selectedPage: null,
  isWayfinding: false,
  isAccessibleMode: false,
  isDetailMediaShow: false,
  screensaverIndex: 0,
  isMoreOpen: false,
  isWifiOpen: false,
  isGetDirection: false,
  isDirect: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PERCENTAGE":
      return {
        ...state,
        percentage: action.payload,
      };
    case "SET_AWM":
      return {
        ...state,
        awm: action.payload,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_MAP_LOADED":
      return {
        ...state,
        mapLoaded: action.payload,
      };
    case "SET_CONFIG_LOADED":
      return {
        ...state,
        configLoaded: action.payload,
      };
    case "SET_MAP_TYPE":
      return {
        ...state,
        mapType: action.payload,
      };
    case "SET_ACTIVE_PAGE":
      return {
        ...state,
        activePage: action.payload,
      };
    case "SET_KEYBOARD_SHOW":
      return {
        ...state,
        isKeyboardShow: action.payload,
      };
    case "SET_SEARCH_FROM_HISTORY":
      return {
        ...state,
        searchFromHistory: action.payload,
      };
    case "SET_SEARCH_TO_HISTORY":
      return {
        ...state,
        searchToHistory: action.payload,
      };

    case "SET_ALL_POIS":
      
      return {
        ...state,
        allPois: action.payload,
      };
    case "SET_ALL_CATEGORIES":
      return {
        ...state,
        allCategories: action.payload,
      };
    case "SET_POIS":
      
      return {
        ...state,
        pois: action.payload,
      };

    case "SET_INDEXED_POIS":
      return {
        ...state,
        indexedPoi: action.payload,
      };
    case "SET_FLOORS":
      return {
        ...state,
        floors: action.payload,
      };
    case "SET_KEYWORD":
      return {
        ...state,
        keyword: action.payload,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "SET_DETAIL_SHOW":
      return {
        ...state,
        isDetailShow: action.payload,
      };
    case "SET_SELECTED_POI":
      return {
        ...state,
        selectedPoi: action.payload,
      };
    case "SET_SELECTED_MULTIPLE_POIS":
      return {
        ...state,
        selectedMultiplePois: action.payload,
      };

    case "SET_SELECTED_SPACE":
      return {
        ...state,
        selectedSpace: action.payload,
      };
    case "SET_CATEGORY_TOGGLE":
      return {
        ...state,
        categoryToggle: action.payload,
      };
    case "SET_LEVEL_TOGGLE":
      return {
        ...state,
        levelToggle: action.payload,
      };
    case "SET_KEYBOARD_TYPE":
      return {
        ...state,
        keyboardType: action.payload,
      };
    case "SET_DESCRIPTION_TOGGLE":
      return {
        ...state,
        descriptionToggle: action.payload,
      };
    case "SET_MEDIAS":
      return {
        ...state,
        medias: action.payload,
      };
    case "SET_POI_DETAIL_PAGE_SHOW":
      return {
        ...state,
        poiDetailPageShow: action.payload,
      };
    case "SET_POI_DETAIL_MULTIPLE_PAGE_SHOW":
      return {
        ...state,
        poiDetailMultiplePageShow: action.payload,
      };
    case "SET_IS_ACTION_ENABLED":
      return {
        ...state,
        isActionEnabled: action.payload,
      };
    case "SET_MEDIA_PAGE":
      return {
        ...state,
        mediaPage: action.payload,
      };
    case "SET_SELECTED_MEDIA":
      return {
        ...state,
        selectedMedia: action.payload,
      };
    case "SET_ROUTING":
      return {
        ...state,
        routing: action.payload,
      };
    case "SET_ROUTING_START":
      return {
        ...state,
        routingStart: action.payload,
      };
    case "SET_AMENITIES_OPEN":
      return {
        ...state,
        isAmenitiesOpen: action.payload,
      };
    case "SET_SCREENSAVERS":
      return {
        ...state,
        screensavers: action.payload,
      };
    case "SET_SCREENSAVERS_SHOW":
      return {
        ...state,
        isScreensaversShow: action.payload,
      };
    case "SET_SCREENSAVER_TIMEOUT":
      return {
        ...state,
        screensaverTimeout: action.payload,
      };
    case "SET_IS_TOILET_OPEN":
      return {
        ...state,
        isToiletOpen: action.payload,
      };
    case "SET_AMENITIES_ACTIVE":
      return {
        ...state,
        amenitiesActive: action.payload,
      };
    case "SET_ALL_AMENITIES":
      return {
        ...state,
        allAmenities: action.payload,
      };
    case "SET_IS_MAIN_OPEN":
      return {
        ...state,
        isMainOpen: action.payload,
      };
    case "SET_SELECTED_CATEGORIES":
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case "SET_SELECTED_LEVELS":
      return {
        ...state,
        selectedLevels: action.payload,
      };
    case "CHANGE_POI_TYPE":
      return {
        ...state,
        poiType: action.payload,
      };
    case "SET_SELECTED_PAGE":
      return {
        ...state,
        selectedPage: action.payload,
      };
    case "SET_IS_WAYFINDING":
      return {
        ...state,
        isWayfinding: action.payload,
      };
    case "SET_IS_ACCESSIBLE_MODE":
      return {
        ...state,
        isAccessibleMode: action.payload,
      };
    case "SET_IS_DETAIL_MEDIA_SHOW":
      return {
        ...state,
        isDetailMediaShow: action.payload,
      };
    case "SET_SCREENSAVER_INDEX":
      return {
        ...state,
        screensaverIndex: action.payload,
      };
    case "SET_IS_MORE_OPEN":
      return {
        ...state,
        isMoreOpen: action.payload,
      };

    case "SET_IS_WIFI_OPEN":
      return {
        ...state,
        isWifiOpen: action.payload,
      };
    case "SET_IS_GET_DIRECTION":
      return {
        ...state,
        isGetDirection: action.payload,
      };
    case "HANDLE_DIRECT_WAYFINDING":
      return {
        ...state,
        isDirect: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;