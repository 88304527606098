import ACA from "@adactive/adsum-utils/services/ClientAPI";
import { Path } from "@adactive/adsum-web-map";
import axios from "axios";
import store from "../store";

export const getPoi = (aca,object) => {
  if (object && object.placeId) {
    return aca.getPoisFromPlace(object.placeId);
  }
  return null;
};

export const getPlace = (aca,object) => {
  const poi = getPoi(aca,object);
  if (poi && poi.length && poi.length > 0) {
    poi[0].placeId = object.placeId;
    return aca.getPlace(object.placeId);
  }
  return null;
};

export const checkComputedPath = async (awm,aca,object) => {
  const state = store.getState();

  try {
    const place = getPlace(aca,object);
    const locationRepo = awm ? awm.wayfindingManager.locationRepository : null;
    const path = new Path(
      locationRepo.userLocation,
      locationRepo.locations.get(place.id)
    );
    await awm.wayfindingManager.computePath(path);
    return true;
  } catch (err) {
    console.log(err, "error path");
    return false;
  }
};

export const getGalleryPlaylist = () => {
  return Array.from(ACA.getPlaylistBy({ name: "Gallery" })[0].medias.values)
    .map((data) => ACA.getMedias([data.value])[0])
    .filter((slider) => {
      const currentDate = new Date();
      if (slider.start_at && slider.end_at) {
        return (
          currentDate >= new Date(slider.start_at) &&
          currentDate <= new Date(slider.end_at)
        );
      }
      return slider;
    })
    .map((media) => {
      const autoPlayIntervalPattern = new RegExp(/[#^[0-9]{1,2}?$/);
      const timePattern = new RegExp(/\d+/);
      let time = null;
      if (
        media &&
        media.description &&
        media.description.match(autoPlayIntervalPattern)
      ) {
        // eslint-disable-next-line prefer-destructuring
        const autoPlayInterval = media.description.match(
          autoPlayIntervalPattern
        )[0];
        if (autoPlayInterval && autoPlayInterval.match(timePattern)) {
          time = autoPlayInterval.match(timePattern) * 1000;
        }
      }
      media.interval = time;
      return media;
    });
};

export const fetchPromoData = async () => {
  const username = "edirectory";
  const password = "<6e>y8@NMK;js";
  const token = `${username}:${password}`;
  const encodedToken = Buffer.from(token).toString("base64");
  const url =
    "https://wdsq-prod.myhabitap.com/api/integration/edirectory/promos/";
  const data = await axios.get(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${encodedToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const promo = data.data.filter((data) => {
    const now = new Date();
    return now > data.periodStart && data.periodEnd;
  });
  return promo.length > 0 && promo;
};

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const toDataURL = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};
