// clearSessionStorageOnHotReload.js

let lastReloadTime = new Date();

export function clearSessionStorageOnHotReload() {
  // Compare current module reload time with previous reload time
  const currentReloadTime = new Date();
  if (currentReloadTime - lastReloadTime < 1000) {
    localStorage.clear();
  }
  lastReloadTime = currentReloadTime;
}
