import { LabelTextObject } from "@adactive/adsum-web-map";

export const getDotPathSectionDrawerOptions = () => ({
  center: true,
  centerOnOptions: {
    fitRatio: 1.5,
    minDistance: 200,
    altitude: 50,
  },
  speed: 80,
  showDelay: 1000,
});

export const iconLabelStyle = {
  offset: {
    x: -100,
    y: 0,
    z: 0,
  },
  style: {
    backgroundColor: "#22419a",
    backgroundOpacity: 0.9,
    backgroundRadius: 10,
    color: "#ffffff",
    size: 10,
  },
};

export const labelStyle = {
  arrival: {
    offset: {
      x: 0,
      y: 40,
      z: 0,
    },
    style: {
      backgroundColor: "#22419a",
      backgroundOpacity: 0.9,
      backgroundRadius: 10,
      color: "#ffffff",
      size: 8,
      lineHeight: 1,
    },
  },
  interchange: {
    offset: {
      x: 0,
      y: 10,
      z: 40,
    },
    style: {
      backgroundColor: "#22419a",
      backgroundOpacity: 0.9,
      backgroundRadius: 10,
      color: "#ffffff",
      size: 8,
    },
  },
};

export const userObjectLabel = new LabelTextObject({
  text: "You are here",
  offset: {
    x: 0,
    y: 0,
    z: 60,
  },
  scale: {
    x: 1,
    y: 1,
    z: 1,
  },
  style: {
    size: 8,
    lineHeight: 1.25,
    // marginTop: "-100px",
    // marginBottom: "-100px",
    // color: '#be272f',
    color: "#e74c3c",
    backgroundColor: "white",
    backgroundOpacity: ".8",
    fontWeight: "bold",
    backgroundRadius: 4.5,
  },
});

export const screenConfig = async () => {
  const configFile = "/config.json";
  let conf;
  try {
    console.groupCollapsed("Config file");
    const response = await fetch(configFile);
    conf = await response.json();
    console.groupEnd();
  } catch (e) {
    throw Error(`Config: unable to find '${configFile}' file`);
  }
  if (!conf) throw Error("Config is empty");
  return conf;
};

export const s3Url = "https://d49qdhygt0dif.cloudfront.net";
export const capitalandS3Url = "https://capitaland.assets.adactive.asia";
export const url = process.env.REACT_APP_BASE_URL;
export const baseUrl = "https://capitaland.api.adactive.asia";
export const siteId = process.env.REACT_APP_SITE_ID;
export const email = process.env.REACT_APP_EMAIL;
export const password = process.env.REACT_APP_PASSWORD;
export const entityName = process.env.REACT_APP_ENTITY_NAME;

export const esplanadeUrl = process.env.REACT_APP_ESPLANADE_BASE_URL;
export const esplanadeSiteId = process.env.REACT_APP_ESPLANADE_SITE_ID;
export const esplanadeEmail = process.env.REACT_APP_ESPLANADE_EMAIL;
export const esplanadePassword = process.env.REACT_APP_ESPLANADE_PASSWORD;
export const capitalandUrl = "https://www.capitaland.com";
