/* eslint-disable */
import { Fragment, useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";
import { Config } from "../utils/const";
import Lottie from "react-lottie";

const CarparkRateDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Carpark Rates"
          onclick={() => {
            navigate.push(`transport`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-9/12 mx-auto h-full flex flex-col gap-4 relative overflow-y-scroll p-4">
          {config != null && config.carparkContentMobile != null ? (
            <Fragment>
              {config != null && config.carparkContentMobile != null && (
                <img
                  src={`${Config.api.s3Url}/media/${config.carparkContentMobile}`}
                  className="w-1/2 mx-auto"
                />
              )}
              <div className="flex-grow flex flex-col w-full items-center">
                {config != null &&
                  config.carparkDescription != null &&
                  config.carparkDescription.replace(/<\/?[^>]+(>|$)/g, "") !=
                    "" && (
                    <div className="flex-grow bg-white p-4 rounded-lg mt-3 flex flex-col w-full">
                      <b className="text-[#444444]">ROAD CLOSURE</b>
                      <div
                        className="flex-grow w-full overflow-y-scroll"
                        dangerouslySetInnerHTML={{
                          __html: config.carparkDescription,
                        }}
                      ></div>
                    </div>
                  )}
                {config != null && config.carparkQrcode && (
                  <img
                    src={`${Config.api.s3Url}/media/${config.carparkQrcode}`}
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <div className="flex flex-col flex-grow w-full pt-[100px] items-center">
              <img
                src={require("../assets/images/icons/no-information.png")}
                className="w-[100px] h-[100px] aspect-square mb-5"
              />
              <div className="bg-white bg-opacity-80 backdrop-blur p-4 rounded-xl">
                <p className="text-lg text-black-primary-100 mb-2 text-center">
                  No Information.
                </p>

                <p className="text-sm text-dark-primary-800 text-start">
                  Apologies, the information that you are looking for is not
                  available yet on the application. Alternatively, you can do
                  one of the following options:
                  <ul
                    className="text-sm ml-4 mt-4"
                    style={{ listStyle: "disc" }}
                  >
                    <li>
                      <span className="font-medium">Visit Concierge</span>
                    </li>
                    <li>
                      <span className="font-medium">Check</span> our website for
                      mor information
                    </li>
                    <li>
                      <span className="font-medium">Chat</span> with us online
                    </li>
                    <li>
                      <span className="font-medium">Contact</span> our Call
                      Centre
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Div100vh>
  );
});

export default CarparkRateDesktop;
