const initialState = {
    isApp: true,
    appLoaded: false,
    searchLoaded: false,
    levelLoaded: false,
    categoryLoaded: false,
    eventLoaded: false,
    promotionLoaded: false,
};

export const transitionReducers = (state = initialState, action) => {
    switch (action.type) {
    case "APP_LOADED":
        return {
            ...state,
            isApp: action.isApp,
            appLoaded: action.isLoaded,
            searchLoaded: false,
            levelLoaded: false,
            categoryLoaded: false,
            eventLoaded: false,
            promotionLoaded: false,
        };
    case "SEARCH_LOADED":
        console.log(action, "action");
        return {
            ...state,
            isApp: action.isApp,
            searchLoaded: action.isLoaded,
            appLoaded: false,
            levelLoaded: false,
            categoryLoaded: false,
            eventLoaded: false,
            promotionLoaded: false,
        };
    case "LEVEL_LOADED":
        return {
            ...state,
            isApp: action.isApp,
            levelLoaded: action.isLoaded,
            searchLoaded: false,
            appLoaded: false,
            categoryLoaded: false,
            eventLoaded: false,
            promotionLoaded: false,
        };
    case "CATEGORY_LOADED":
        return {
            ...state,
            isApp: action.isApp,
            categoryLoaded: action.isLoaded,
            searchLoaded: false,
            levelLoaded: false,
            appLoaded: false,
            eventLoaded: false,
            promotionLoaded: false,
        };
    case "EVENT_LOADED":
        return {
            ...state,
            isApp: action.isApp,
            categoryLoaded: false,
            searchLoaded: false,
            levelLoaded: false,
            appLoaded: false,
            eventLoaded: action.isLoaded,
            promotionLoaded: false,
        };
    case "PROMOTION_LOADED":
        return {
            ...state,
            isApp: action.isApp,
            categoryLoaded: false,
            searchLoaded: false,
            levelLoaded: false,
            appLoaded: false,
            eventLoaded: false,
            promotionLoaded: action.isLoaded,
        };
    default:
        return state;
    }
};
