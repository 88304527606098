/* eslint-disable */
import { useStores } from "../../use.store";
import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { FaReply, FaTimes, FaWalking } from "react-icons/fa";
import Const, { Config } from "../../utils/const";
import { HiCalendar, HiLocationMarker, HiX } from "react-icons/hi";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import moment from "moment";

const DetailMedia = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const { wayFindingControlsState, currentSite, isActionEnabled } = useSelector(
    (state) => ({
      poiType: state.app.poiType,
      isWayfinding: state.app.isWayfinding,
      disableTmtState: state.disableTmt,
      wayFindingControlsState: state.wayFindingControls,
      awm: state.app.awm,
      currentFloor: state.map.currentFloor,
      floors: state.app.floors,
      getPath: state.map.getPath,
      isActionEnabled: state.app.isActionEnabled,
      wayfindingState: state.map.wayfindingState,
      currentSite: state.capitaland.currentSite,
    })
  );

  const selectedMedia = generalStore.media;
  const [detail, setDetail] = useState(true);
  const sheetRef = useRef();

  return (
    selectedMedia && (
      <div className="w-full h-full z-[999] fixed top-0 bg-[#00000057] flex items-center justify-center">
        <div className="mx-auto bg-white w-1/2 absolute shadow-lg rounded-lg max-h-[40vw] flex flex-col">
          <div className="flex flex-row justify-between items-center p-4">
            <span className="font-semibold">Detail</span>
            <HiX
              fontSize={20}
              onClick={() => {
                generalStore.media = null;
              }}
            />
          </div>
          <div className="w-full h-full px-4 pb-4 flex flex-row overflow-y-scroll gap-4">
            <img
              loader={
                <img
                  src={require("../../assets/images/icons/img-default.png")}
                />
              }
              src={`${Config.api.capitalandExternalUrl}/${selectedMedia.thumbnail}`}
              className=" object-cover w-72 h-64 aspect-video rounded-lg"
            />
            <div className="w-full flex flex-col overflow-y-scroll">
              <h1 className="font-semibold mb-4 text-lg">
                {selectedMedia["jcr:title"]}
              </h1>
              <div
                className="flex flex-row gap-2 divide-x-2 mt-2 divide-gray-300"
                style={{
                  color:
                    config == null
                      ? Const.mainColor
                      : config != null
                      ? config.primaryColor
                      : Const.mainColor,
                }}
              >
                <div className="flex flex-row items-center">
                  <HiLocationMarker />
                  <span className="ml-1 text-xs line-clamp-1">
                    {selectedMedia &&
                    selectedMedia._rel_properties_details &&
                    selectedMedia._rel_properties_details.length > 0 &&
                    selectedMedia._rel_properties_details.find(
                      (x) => x.entityname === config.propertiesName
                    )
                      ? selectedMedia._rel_properties_details.find(
                          (x) => x.entityname === config.propertiesName
                        )["jcr:title"]
                      : generalStore.selectedSite.name}
                  </span>
                </div>
                <div className="flex flex-row items-center pl-1">
                  <HiCalendar />
                  <span className="ml-1 text-xs line-clamp-1">
                    {selectedMedia.source && selectedMedia.source === "adsign"
                      ? selectedMedia.start && selectedMedia.end
                        ? `${moment(new Date(selectedMedia.start)).format(
                            "DD/MM/YYYY"
                          )} - ${moment(new Date(selectedMedia.end)).format(
                            "DD/MM/YYYY"
                          )}`
                        : selectedMedia.start && !selectedMedia.end
                        ? moment(new Date(selectedMedia.start)).format(
                            "DD/MM/YYYY"
                          )
                        : !selectedMedia.start && selectedMedia.end
                        ? `Ends at ${moment(new Date(selectedMedia.end)).format(
                            "DD/MM/YYYY"
                          )}`
                        : "All Day"
                      : selectedMedia.startdate && selectedMedia.enddate
                      ? `${moment(new Date(selectedMedia.startdate)).format(
                          "DD/MM/YYYY"
                        )} - ${moment(new Date(selectedMedia.enddate)).format(
                          "DD/MM/YYYY"
                        )}`
                      : selectedMedia.startdate && !selectedMedia.enddate
                      ? moment(new Date(selectedMedia.startdate)).format(
                          "DD/MM/YYYY"
                        )
                      : !selectedMedia.startdate && selectedMedia.enddate
                      ? `Ends at ${moment(
                          new Date(selectedMedia.enddate)
                        ).format("DD/MM/YYYY")}`
                      : "All Day"}
                  </span>
                </div>
              </div>
              <hr className="my-4 border-t border-gray-200" />
              <div
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: selectedMedia["jcr:description"],
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default DetailMedia;
