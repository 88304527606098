/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";

const Reward = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  useEffect(() => {
    initData();
  }, []);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout); // Clear previous timeout
    const value = event.target.value;
    timeout = setTimeout(() => {
      setQuery(value);
      submitSearch(value);
    }, 300);
  };

  const submitSearch = (q) => {
    let data = generalStore.pois
      .filter((poi) => _.map(poi.categoriesDetails, "name").includes("Reward"))
      .filter((e) => {
        return q == ""
          ? true
          : e.name.toString().toLowerCase().indexOf(q.toLowerCase()) === 0;
      })
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
  };

  const initData = () => {
    let data = generalStore.pois
      .filter((poi) => _.map(poi.categoriesDetails, "name").includes("Reward"))
      .reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
    setPoiList(Object.values(data));
  };

  function getCategoryNameById(categories, categoryId) {
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : undefined;
  }

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Rewards"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full h-full flex flex-col relative overflow-y-scroll p-4">
          {config != null && config.rewardMediaMobile != null && (
            <img
              src={`${capitalandS3Url}/media/${config.rewardMediaMobile}`}
              className="rounded-lg"
            />
          )}
        </div>
      </div>
    </Div100vh>
  );
});

export default Reward;
