/* eslint-disable */
import { Route, BrowserRouter as BaseRoutes, Switch } from "react-router-dom";
import { useStores } from "./use.store";
import Splashscreen from "./mobile/Splashscreen";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Dashboard from "./mobile/Dashboard";
import MainMenu from "./mobile/MainMenu";
import Directory from "./mobile/Directory";
import FloorGuide from "./mobile/FloorGuide";
import Fashion from "./mobile/Fashion";
import Dine from "./mobile/Dine";
import Promotion from "./mobile/Promotion";
import Reward from "./mobile/Reward";
import Happening from "./mobile/Happening";
import MallInfo from "./mobile/MallInfo";
import Tourist from "./mobile/Tourist";
import Transport from "./mobile/Transport";
import CarparkRate from "./mobile/CarparkRate";
import PublicTransport from "./mobile/PublicTransport";
import { isMobile } from "react-device-detect";
import SplashscreenDesktop from "./desktop/Splashscreen";
import DashboardDesktop from "./desktop/Dashboard";
import MainMenuDesktop from "./desktop/MainMenu";
import DirectoryDesktop from "./desktop/Directory";
import FashionDesktop from "./desktop/Fashion";
import DineDesktop from "./desktop/Dine";
import PromotionDesktop from "./desktop/Promotion";
import RewardDesktop from "./desktop/Reward";
import HappeningDesktop from "./desktop/Happening";
import MallInfoDesktop from "./desktop/MallInfo";
import TouristDesktop from "./desktop/Tourist";
import TransportDesktop from "./desktop/Transport";
import CarparkRateDesktop from "./desktop/CarparkRate";
import PublicTransportDesktop from "./desktop/PublicTransport";
import FloorGuideDesktop from "./desktop/FloorGuide";

const Routes = observer(() => {
  const { generalStore } = useStores();
  window.addEventListener("beforeunload", (event) => {
    if(generalStore.selectedSite != null){
      window.location.href = "/map?mall="+generalStore.selectedSite.name
    }
  });
  if (isMobile) {
    return generalStore.loading ? (
      <Splashscreen />
    ) : (
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/map" exact>
          <MainMenu />
        </Route>
        <Route path="/directory" exact>
          <Directory />
        </Route>
        <Route path="/fashion" exact>
          <Fashion />
        </Route>
        <Route path="/experience" exact>
          <Fashion />
        </Route>
        <Route path="/dine" exact>
          <Dine />
        </Route>
        <Route path="/promotion" exact>
          <Promotion />
        </Route>
        <Route path="/rewards" exact>
          <Reward />
        </Route>
        <Route path="/happenings" exact>
          <Happening />
        </Route>
        <Route path="/mall-info" exact>
          <MallInfo />
        </Route>
        <Route path="/tourist" exact>
          <Tourist />
        </Route>
        <Route path="/transport" exact>
          <Transport />
        </Route>
        <Route path="/carpark-rate" exact>
          <CarparkRate />
        </Route>
        <Route path="/public-transport" exact>
          <PublicTransport />
        </Route>
        <Route path="/floor-guide" exact>
          <FloorGuide />
        </Route>
      </Switch>
    );
  } else {
    return generalStore.loading ? (
      <SplashscreenDesktop />
    ) : (
      <Switch>
        <Route path="/" exact>
          <DashboardDesktop />
        </Route>
        <Route path="/map" exact>
          <MainMenuDesktop />
        </Route>
        <Route path="/directory" exact>
          <DirectoryDesktop />
        </Route>
        <Route path="/fashion" exact>
          <FashionDesktop />
        </Route>
        <Route path="/experience" exact>
          <FashionDesktop />
        </Route>
        <Route path="/dine" exact>
          <DineDesktop />
        </Route>
        <Route path="/promotion" exact>
          <PromotionDesktop />
        </Route>
        <Route path="/rewards" exact>
          <RewardDesktop />
        </Route>
        <Route path="/happenings" exact>
          <HappeningDesktop />
        </Route>
        <Route path="/mall-info" exact>
          <MallInfoDesktop />
        </Route>
        <Route path="/tourist" exact>
          <TouristDesktop />
        </Route>
        <Route path="/transport" exact>
          <TransportDesktop />
        </Route>
        <Route path="/carpark-rate" exact>
          <CarparkRateDesktop />
        </Route>
        <Route path="/public-transport" exact>
          <PublicTransportDesktop />
        </Route>
        <Route path="/floor-guide" exact>
          <FloorGuideDesktop />
        </Route>
      </Switch>
    );
  }
});

export default Routes;
