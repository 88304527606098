import React from 'react';

import { Link } from "react-router-dom";

import { FiClock, FiWifi, IconName } from "react-icons/fi";
import { useSelector } from 'react-redux';

function SidebarContainer(props) {
    const {
        selectedMall,
        primaryColor,
        secondaryColor,
        apiToken,
        categoriesTenant,
        tenants,
    } = useSelector(
        (state) => ({
            selectedMall: state.Adsign.selectedMall,
            apiToken: state.Adsign.apiToken,
            tenants: state.Adsign.tenants,
            categoriesTenant: state.Adsign.categoriesTenant,
            primaryColor: state.Adsign.primaryColor,
            secondaryColor: state.Adsign.secondaryColor,
        })
    );
    return (
        <React.Fragment>
            <div className={`h-screen w-screen bg-[#00000057] fixed right-0 top-0 z-50 flex flex-col`}>
                <div className='h-screen absolute right-0 bg-white flex flex-col p-3 w-1/4'>
                    {props.header}
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SidebarContainer;