export const setPercentage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_PERCENTAGE",
      payload: value,
    });
  };
};

export const setAwm = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_AWM",
      payload: value,
    });
  };
};

export const setIsLoading = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_LOADING",
      payload: value,
    });
  };
};

export const setMapLoaded = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MAP_LOADED",
      payload: value,
    });
  };
};

export const setConfigLoaded = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CONFIG_LOADED",
      payload: value,
    });
  };
};

export const setMapType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MAP_TYPE",
      payload: value,
    });
  };
};

export const setActivePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      payload: value,
    });
  };
};

export const setKeyboardShow = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_KEYBOARD_SHOW",
      payload: value,
    });
  };
};

export const setPois = (value) => {
  
  return (dispatch) => {
    dispatch({
      type: "SET_POIS",
      payload: value,
    });
  };
};

export const setIndexedPois = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_INDEXED_POIS",
      payload: value,
    });
  };
};


export const setAllPois = (value) => {
  
  return (dispatch) => {
    dispatch({
      type: "SET_ALL_POIS",
      payload: value,
    });
  };
};

export const setAllCategories = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ALL_CATEGORIES",
      payload: value,
    });
  };
};

export const setSearchFromHistory = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SEARCH_FROM_HISTORY",
      payload: value,
    });
  };
};

export const setSearchToHistory = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SEARCH_TO_HISTORY",
      payload: value,
    });
  };
};

export const setKeyword = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_KEYWORD",
      payload: value,
    });
  };
};

export const setCategories = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CATEGORIES",
      payload: value,
    });
  };
};

export const setDetailShow = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_DETAIL_SHOW",
      payload: value,
    });

    if (cb) {
      cb();
    }
  };
};

export const SetSelectedPoi = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_POI",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const SetSelectedSpace = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_SPACE",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const setFloors = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_FLOORS",
      payload: value,
    });
  };
};

export const setCategoryToggle = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CATEGORY_TOGGLE",
      payload: value,
    });
  };
};

export const setLevelToggle = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LEVEL_TOGGLE",
      payload: value,
    });
  };
};

export const setKeyboardType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_KEYBOARD_TYPE",
      payload: value,
    });
  };
};

export const setToggleDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_DESCRIPTION_TOGGLE",
      payload: value,
    });
  };
};

export const setMedias = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MEDIAS",
      payload: value,
    });
  };
};

export const setPoiDetailPageShow = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_POI_DETAIL_PAGE_SHOW",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const setIsActionEnabled = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_ACTION_ENABLED",
      payload: value,
    });
  };
};

export const setMediaPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MEDIA_PAGE",
      payload: value,
    });
  };
};

export const setSelectedMedia = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_MEDIA",
      payload: value,
    });
  };
};

export const setRouting = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ROUTING",
      payload: value,
    });
  };
};

export const setRoutingStart = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ROUTING_START",
      payload: value,
    });
  };
};

export const setIsAmenitiesOpen = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_AMENITIES_OPEN",
      payload: value,
    });
  };
};

export const setScreensavers = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SCREENSAVERS",
      payload: value,
    });
  };
};

export const setIsScreensaversShow = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SCREENSAVERS_SHOW",
      payload: value,
    });
  };
};

export const setScreensaverTimeout = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SCREENSAVER_TIMEOUT",
      payload: value,
    });
  };
};

export const setIsToiletOpen = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_TOILET_OPEN",
      payload: value,
    });
  };
};

export const setAmenitiesActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_AMENITIES_ACTIVE",
      payload: value,
    });
  };
};

export const setAllAmenities = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ALL_AMENITIES",
      payload: value,
    });
  };
};

export const setIsMainOpen = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_MAIN_OPEN",
      payload: value,
    });
  };
};

export const setSelectedCategories = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_CATEGORIES",
      payload: value,
    });
  };
};

export const setSelectedLevels = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_LEVELS",
      payload: value,
    });
  };
};

export const changePoiType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_POI_TYPE",
      payload: value,
    });
  };
};

export const setSelectedPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_PAGE",
      payload: value,
    });
  };
};

export const setIsWayfinding = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_WAYFINDING",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const setIsAccessibleMode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_ACCESSIBLE_MODE",
      payload: value,
    });
  };
};

export const setIsDetailMediaShow = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_DETAIL_MEDIA_SHOW",
      payload: value,
    });
  };
};

export const setScreensaverIndex = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SCREENSAVER_INDEX",
      payload: value,
    });
  };
};

export const setIsMoreOpen = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_MORE_OPEN",
      payload: value,
    });
  };
};

export const setIsWifiOpen = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_WIFI_OPEN",
      payload: value,
    });
  };
};

export const setIsGetDirection = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_GET_DIRECTION",
      payload: value,
    });
  };
};

export const handleDirectWayfinding = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "HANDLE_DIRECT_WAYFINDING",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const setToggleFashionCategory = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_TOGGLE_FASHION_CATEGORY",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};

export const setIsViaLift = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_VIA_LIFT",
      payload: value,
    });
  };
};

export const setSelectedAmenities = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_AMENITIES",
      payload: value,
    });
  };
};

export const resetApp = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_APP" });
  };
};

export const setPoiDetailMultiplePageShow = (value, cb) => {
  return (dispatch) => {
    dispatch({ type: "SET_POI_DETAIL_MULTIPLE_PAGE_SHOW", payload: value });
    if (cb) {
      cb();
    }
  };
};

export const setSelectedMultiplePois = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_SELECTED_MULTIPLE_POIS", payload: value });
  };
};

export const resetColor = (cb) => {
  return (dispatch, getState) => {
    const { awm, selectedPoi } = getState().app;
    cb({ awm, selectedPoi });
  };
};

export const resetColorMultiple = (cb) => {
  return (dispatch, getState) => {
    const { awm, selectedMultiplePois } = getState().app;
    cb({ awm, selectedMultiplePois });
  };
};

export const setSteps = (value, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_STEPS",
      payload: value,
    });
    if (cb) {
      cb();
    }
  };
};
