/* eslint-disable */
import { useStores } from "../../use.store";
import React from "react";
import { observer } from "mobx-react-lite";
import { HiArrowNarrowLeft, HiOutlineHome } from "react-icons/hi";
import { FiClock, FiWifi, IconName } from "react-icons/fi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const BreadCrumb = observer(({title, onclick,onHomeClick}) => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  return (
    <div className="w-screen text-gray bg-slate-200 z-10 py-3">
      <div className="flex flex-row px-3 w-screen gap-1 items-center">
        {<HiArrowNarrowLeft onClick={onclick} fontSize={20} />}
        <span className="flex-grow font-medium text-sm uppercase line-clamp-1">
          {title}
        </span>
        {
          <HiOutlineHome
            fontSize={20}
            onClick={onHomeClick}
            color={config != null ? config.primaryColor : "blue"}
          />
        }
      </div>
    </div>
  );
});

export default BreadCrumb;
