const initialState = {
  whatsOn: [],
  selectedWhatsOn: null,
  whatsOnDetailsShow: false,
  categoryToggle: false,
  levelToggle: false,
  selectedCategories: [],
  selectedLevels: [],
};

const esplanadeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WHATS_ON":
      return {
        ...state,
        whatsOn: action.payload,
      };
    case "SET_SELECTED_WHATS_ON":
      return {
        ...state,
        selectedWhatsOn: action.payload,
      };
    case "SET_WHATS_ON_DETAILS_SHOW":
      return {
        ...state,
        whatsOnDetailsShow: action.payload,
      };
    case "SET_SELECTED_CATEGORIES_WHATSON":
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case "SET_SELECTED_LEVELS_WHATSON":
      return {
        ...state,
        selectedLevels: action.payload,
      };
    case "SET_CATEGORY_WHATSON_TOGGLE":
      return {
        ...state,
        categoryToggle: action.payload,
      };
    case "SET_LEVEL_WHATSON_TOGGLE":
      return {
        ...state,
        levelToggle: action.payload,
      };

    default:
      return state;
  }
};

export default esplanadeReducer;
