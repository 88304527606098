/* eslint-disable */
import { useStores } from "../../use.store";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { FaReply, FaTimes, FaWalking } from "react-icons/fa";
import Const, { Config } from "../../utils/const";
import { HiLocationMarker, HiX } from "react-icons/hi";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import Tags from "./Tags";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkComputedPath } from "../../utils/functions";
const DetailPoi = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const { wayFindingControlsState, isActionEnabled } = useSelector((state) => ({
    poiType: state.app.poiType,
    isWayfinding: state.app.isWayfinding,
    disableTmtState: state.disableTmt,
    wayFindingControlsState: state.wayFindingControls,
    awm: state.app.awm,
    currentFloor: state.map.currentFloor,
    floors: state.app.floors,
    getPath: state.map.getPath,
    isActionEnabled: state.app.isActionEnabled,
    wayfindingState: state.map.wayfindingState,
  }));

  const currentSite = generalStore.selectedSite;
  const selectedPoi = generalStore.to;
  const [detail, setDetail] = useState(true);
  
  const sheetRef = useRef();
  const navigate = useHistory();

  const [isSameFloor, setIsSameFloor] = useState(false);
  const [isRoutable, setIsRoutable] = useState(true);
  useEffect(() => {
    initPath();
  }, []);

  const initPath = () => {
    if (generalStore.to && generalStore.from) {
      if (
        generalStore.to.locations.length > 0 &&
        generalStore.from.locations.length > 0
      ) {
        if (
          generalStore.to.locations[0].floor_id ==
          generalStore.from.locations[0].floor_id
        ) {
          setIsSameFloor(true);
        }
        const object = generalStore.awm.objectManager.getSpace(
          generalStore.to.locations[0].shape_id
        );
        if (!checkComputedPath(generalStore.awm, generalStore.aca, object)) {
          setIsRoutable(false);
        }
      } else {
        if (window.location.pathname != "/floor-guide") {
          setIsRoutable(false);
        }
      }
    }
  };

  return (
    selectedPoi && (
      <Fragment>
        {wayFindingControlsState &&
          wayFindingControlsState.placeDestination &&
          wayFindingControlsState.finish &&
          !detail && (
            <button
              onClick={(e) => {
                generalStore.tmtType = "replay";
              }}
              className={`text-white bg-[${config.primaryColor}] font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center absolute left-1/2 ml-[-55px] z-[11] bottom-[70px]`}
            >
              Replay
              <img
                src={require("../../assets/images/icons/repeat.png")}
                className="ml-3"
              />
            </button>
          )}

        <BottomSheet
          scrollLocking={false}
          ref={sheetRef}
          open={selectedPoi != null}
          draggable={false}
          snapPoints={({ minHeight, maxHeight }) => [60, maxHeight / 1.08]}
          defaultSnap={({ maxHeight }) => maxHeight / 1.08}
          onSpringStart={async (event) => {
            if (sheetRef.current.height > 300) {
              setDetail(true);
            } else {
              setDetail(false);
            }
          }}
          // onSpringCancel={async (event) => {
          //   if (sheetRef.current.height < 300) {
          //     setDetail(false);
          //   } else {
          //     setDetail(true);
          //   }
          // }}

          onSpringEnd={async (event) => {
            if (sheetRef.current.height > 300) {
              setDetail(true);
            } else {
              setDetail(false);
            }
          }}
          blocking={
            wayFindingControlsState &&
            wayFindingControlsState.placeDestination &&
            wayFindingControlsState.placeDestination.name
              ? false
              : true
          }
          header={
            <div>
              <div className="flex flex-row justify-between items-center gap-2">
                {!detail && <div className="flex flex-row flex-grow items-center gap-2">
                  <img
                    src="default.png"
                    data-src={
                      selectedPoi.logo != null
                        ? `${Config.api.capitalandExternalUrl}${selectedPoi.logo}`
                        : "default.png"
                    }
                    onError={(element) => {
                      element.currentTarget.src = "default.png";
                    }}
                    onLoad={(element) => {
                      element.currentTarget.src =
                        element.currentTarget.getAttribute("data-src");
                    }}
                    className="object-cover w-8 h-8 aspect-square"
                  />
                  <span className="flex-grow text-start line-clamp-1 font-bold">
                    { selectedPoi["name"] }
                  </span>
                </div>}
                {detail && <span className="flex-grow text-start line-clamp-1 font-bold">Detail</span>}
                {((wayFindingControlsState &&
                  wayFindingControlsState.placeDestination &&
                  wayFindingControlsState.placeDestination.name &&
                  wayFindingControlsState.finish) ||
                  isActionEnabled) &&
                  window.location.pathname == "/floor-guide" && (
                    <HiX
                      fontSize={20}
                      onClick={() => {
                        generalStore.to = null;
                      }}
                    />
                  )}
                {window.location.pathname != "/floor-guide" && (
                  <HiX
                    fontSize={20}
                    onClick={() => {
                      generalStore.to = null;
                    }}
                  />
                )}
              </div>
            </div>
          }
          className="absolute z-50"
        >
          {/* <div className="p-4">
            {!detail && selectedPoi != null && selectedPoi["name"] != null && (
              <div className="w-full h-full flex flex-row items-center gap-3 pb-4">
                <img
                  src="default.png"
                  data-src={
                    selectedPoi.logo != null
                      ? `${Config.api.capitalandExternalUrl}${selectedPoi.logo}`
                      : "default.png"
                  }
                  onError={(element) => {
                    element.currentTarget.src = "default.png";
                  }}
                  onLoad={(element) => {
                    element.currentTarget.src =
                      element.currentTarget.getAttribute("data-src");
                  }}
                  className="rounded-full aspect-square w-20 h-20 object-cover"
                />
                <div className="flex-grow flex flex-col">
                  <div className="flex-grow flex flex-col p-2 w-full">
                    <span className="text-sm font-medium">
                      {selectedPoi["name"]}
                    </span>

                    <div className="flex-grow flex flex-row overflow line-clamp-1-hidden gap-3">
                      <span className="text-sm font-light text-gray-500 line-clamp-1 border-">
                        {_.map(selectedPoi.categoriesDetails, "name").join(
                          ", "
                        )}
                      </span>

                      <div className="flex flex-row">
                        <HiLocationMarker
                          fontSize={16}
                          color={config.primaryColor}
                        />
                        <span
                          style={{ color: `${config.primaryColor}` }}
                          className="text-sm"
                        >
                          {selectedPoi.locations.length > 0
                            ? _.map(
                                selectedPoi.locations,
                                "name"
                              )[0].replaceAll("_", " ")
                            : currentSite.name}
                        </span>
                      </div>
                    </div>
                    <span
                      className="text-orange text-sm mt-1 font-medium"
                      onClick={() => {
                        // setDetail(true);
                        sheetRef.current.snapTo(({ snapPoints }) =>
                          Math.max(...snapPoints)
                        );
                      }}
                      style={{ color: Const.orange }}
                    >
                      MORE DETAILS
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          {detail && selectedPoi != null && selectedPoi["name"] != null && (
            <div className="flex flex-col-reverse w-full h-full overflow-scroll">
              {selectedPoi.locations.length > 0 && isRoutable && (
                <div className="flex flex-row fixed bottom-0 w-full z-10 bg-white">
                  {!isSameFloor && (
                    <Fragment>
                      <div
                        onClick={(e) => {
                          if (window.location.pathname == "/floor-guide") {
                            setTimeout(() => {
                              sheetRef.current.snapTo(0, {
                                source: "snap-to-bottom",
                              });
                              generalStore.tmtType = "escalator";
                            }, 1000);
                          } else {
                            generalStore.tmtType = "";
                            navigate.push("/floor-guide");
                          }
                          // onTmtt(e, false);
                        }}
                        className={`flex-1 bg-[${
                          config ? config.tertiaryColor : "#F19743"
                        }] h-16 flex items-center justify-center`}
                      >
                        <FaWalking fontSize={20} color="white" />
                        <span className="font-semibold text-white text-sm">
                          {generalStore.selectedSite.name == "Clarke Quay"
                            ? "STAIRS"
                            : "ESCALATOR"}
                        </span>
                      </div>
                      <div
                        onClick={(e) => {
                          if (window.location.pathname == "/floor-guide") {
                            setTimeout(() => {
                              sheetRef.current.snapTo(0, {
                                source: "snap-to-bottom",
                              });
                              generalStore.tmtType = "lift";
                            }, 1000);
                          } else {
                            generalStore.tmtType = "";
                            navigate.push("/floor-guide");
                          }
                        }}
                        className={`flex-1 bg-[${
                          config ? config.secondaryColor : "#F19743"
                        }] h-16 flex items-center justify-center`}
                      >
                        <img
                          src={require("../../assets/images/icons/lift.png")}
                          className="w-5 mr-2"
                        />
                        <span className="font-semibold text-white text-sm">
                          LIFT
                        </span>
                      </div>
                    </Fragment>
                  )}
                  {isSameFloor && (
                    <div
                      onClick={(e) => {
                        if (window.location.pathname == "/floor-guide") {
                          setTimeout(() => {
                            sheetRef.current.snapTo(0, {
                              source: "snap-to-bottom",
                            });
                            generalStore.tmtType = "escalator";
                          }, 1000);
                        } else {
                          generalStore.tmtType = "";
                          navigate.push("/floor-guide");
                        }
                      }}
                      className={`flex-1 bg-[${
                        config ? config.tertiaryColor : "#F19743"
                      }] h-16 flex items-center justify-center`}
                    >
                      <FaWalking fontSize={20} color="white" />
                      <span className="font-semibold text-white text-sm">
                        GET DIRECTION
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div
                className="flex-grow w-full flex-col overflow-scroll border-b p-4"
                style={{ paddingBottom: "100px" }}
              >
                <img
                  src="default.png"
                  data-src={
                    selectedPoi.logo != null
                      ? `${Config.api.capitalandExternalUrl}${selectedPoi.logo}`
                      : "default.png"
                  }
                  onError={(element) => {
                    element.currentTarget.src = "default.png";
                  }}
                  onLoad={(element) => {
                    element.currentTarget.src =
                      element.currentTarget.getAttribute("data-src");
                  }}
                  className=" object-cover w-full"
                />
                <div className="p-2">
                  <h1 className="font-semibold mb-2 text-md">
                    {selectedPoi["name"]}
                  </h1>
                  <span className="font-thin text-md text-gray mt-4">
                    {_.map(selectedPoi.categoriesDetails, "name").join(", ")}
                  </span>
                  <div
                    className="flex text-sm flex-row gap-2 divide-x-2 divide-gray-300"
                    style={{ color: `${config.primaryColor}` }}
                  >
                    {selectedPoi.locations.length > 0 && (
                      <div className="flex flex-row items-center">
                        <HiLocationMarker
                          fontSize={16}
                          color={config.primaryColor}
                        />
                        <span
                          style={{ color: `${config.primaryColor}` }}
                          className="text-sm"
                        >
                          {selectedPoi.locations.length > 0
                            ? _.map(
                                selectedPoi.locations,
                                "name"
                              )[0].replaceAll("_", " ")
                            : currentSite.name}
                        </span>
                      </div>
                    )}
                    {selectedPoi.startdate != null && (
                      <div className="flex flex-row items-center pl-1">
                        <HiCalendar />
                        <span className="ml-1 text-sm">
                          {selectedPoi.source && selectedPoi.source === "adsign"
                            ? selectedPoi.start && selectedPoi.end
                              ? `${moment(new Date(selectedPoi.start)).format(
                                  "DD/MM/YYYY"
                                )} - ${moment(new Date(selectedPoi.end)).format(
                                  "DD/MM/YYYY"
                                )}`
                              : selectedPoi.start && !selectedPoi.end
                              ? moment(new Date(selectedPoi.start)).format(
                                  "DD/MM/YYYY"
                                )
                              : !selectedPoi.start && selectedPoi.end
                              ? `Ends at ${moment(
                                  new Date(selectedPoi.end)
                                ).format("DD/MM/YYYY")}`
                              : "All Day"
                            : selectedPoi.startdate && selectedPoi.enddate
                            ? `${moment(new Date(selectedPoi.startdate)).format(
                                "DD/MM/YYYY"
                              )} - ${moment(
                                new Date(selectedPoi.enddate)
                              ).format("DD/MM/YYYY")}`
                            : selectedPoi.startdate && !selectedPoi.enddate
                            ? moment(new Date(selectedPoi.startdate)).format(
                                "DD/MM/YYYY"
                              )
                            : !selectedPoi.startdate && selectedPoi.enddate
                            ? `Ends at ${moment(
                                new Date(selectedPoi.enddate)
                              ).format("DD/MM/YYYY")}`
                            : "All Day"}
                        </span>
                      </div>
                    )}
                  </div>
                  <Tags />
                  <hr className="my-4 border-t border-gray-200" />
                  <div
                    className="w-full text-sm"
                    dangerouslySetInnerHTML={{
                      __html: selectedPoi.description,
                    }}
                  ></div>
                  <div className="w-full flex justify-center">
                    {selectedPoi.chopelink && (
                      <img
                        onClick={() => {
                          window.open(selectedPoi.chopelink, "_blank").focus();
                        }}
                        className="w-32 mt-4"
                        src={require("../../assets/images/icons/chope.png")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </BottomSheet>
      </Fragment>
    )
  );
});

export default DetailPoi;
