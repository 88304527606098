/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Loading = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();

  return (
    <Div100vh>
      <div className="w-full h-full bg-[#00000095] flex flex-col absolute top-0 left-0 z-50 items-center justify-center gap-2">
        <div className="spinner" style={{borderTopColor : generalStore.selectedSite.config.primaryColor,borderLeftColor : generalStore.selectedSite.config.primaryColor}}/>
        <span className="font-medium text-xs text-white">Loading Assets..</span>
      </div>
    </Div100vh>
  );
});

export default Loading;
