/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import _, { ceil } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { capitalandS3Url, capitalandUrl, s3Url } from "../utils/constants";
import { HiLocationMarker } from "react-icons/hi";
import DetailDeal from "./components/DetailDeal";

const Promotion = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const config = generalStore.selectedSite.config;
  const deals = generalStore.deals;
  const [poiList, setPoiList] = useState([]);
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistroy] = useState([]);
  const dispatch = useDispatch();

  return (
    <Div100vh>
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Promotions"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
          }}
        />
        <Background />
        <div className="w-full flex-grow flex flex-col relative overflow-hidden">
          <div className="flex-grow w-full grid grid-cols-2 gap-4 px-4 py-4 content-start overflow-y-scroll">
            {deals.map((deal) => {
              return (
                <div
                  key={deal['jcr:title'] + "group"}
                  onClick={() => {
                    generalStore.deal = deal;
                  }}
                  className="w-full h-[calc(100vw/2 + 50)] flex flex-col gap-2  bg-white rounded-lg relative"
                >
                  {deal.ribbon && (
                    <div className="-ml-2.5 mr-0 my-2 absolute w-1/2 z-10">
                      <span
                        className={`w-full relative text-white text-[10px] text-center tracking-[2px] leading-[20px] inline-block px-2 py-0 rounded-sm ribbon`}
                        style={{backgroundColor : deal.ribboncolor,}}
                      >
                        <div className="triangle" style={{borderLeftColor:deal.ribboncolor}}>

                        </div>
                        <div className="marquee">
                          <div className="marquee-wrapper">
                            <span>{deal.ribbon}</span>
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                  <img
                    className="object-cover w-full aspect-square rounded-t-lg"
                    src="default.png"
                    data-src={
                      deal.thumbnail
                        ? `${capitalandUrl}/${deal.thumbnail}`
                        : "default.png"
                    }
                    onError={(element) => {
                      element.currentTarget.src = "default.png";
                    }}
                    onLoad={(element) => {
                      element.currentTarget.src =
                        element.currentTarget.getAttribute("data-src");
                    }}
                  />
                  <div className="flex-grow w-full flex flex-col gap-2 px-2 justify-between pb-2">
                    <h2 className="font-medium text-sm line-clamp-2">
                      {deal["jcr:title"]}
                    </h2>
                    <p className="font-thin text-sm text-gray mt-2 line-clamp-1 text-capitalize">
                      {deal.source}
                      {deal.source && deal.source === "adsign"
                        ? findPois(deal.tenants)
                        : deal._rel_tenants_details[0]["jcr:title"]}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DetailDeal/>
    </Div100vh>
  );
});

export default Promotion;
