/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useStores } from "../use.store";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ceil } from "lodash";
import Header from "./components/Header";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TimeCard from "./components/TimeCard";
import { capitalandS3Url } from "../utils/constants";
import MenuCard from "./components/MenuCard";
import Background from "./components/Background";
import { setMapLoaded } from "../store/app/appActions";

const MainMenuDesktop = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.app.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (generalStore.to != null) {
      navigate.push("floor-guide");
    }
  }, [generalStore.to]);

  useEffect(() => {
    dispatch(setMapLoaded(false));
    setTimeout(() => {
      setIsPageLoaded(true)
    }, 300);
  }, []);

  const listCard = [
    {
      name: "directory",
      bg: "directoryBackgroundMobile",
    },
    {
      name: generalStore.selectedSite.name != "Clarke Quay" ? "fashion" :"experience",
      bg: "fashionBackgroundMobile",
    },
    {
      name: "dine",
      bg: "dineBackgroundMobile",
    },
    {
      name: "promotions",
      bg: "promotionBackgroundMobile",
    },
    {
      name: "floor_guide",
      bg: "floorBackgroundMobile",
    },
    {
      name: "rewards",
      bg: "rewardBackgroundMobile",
    },
    {
      name: "happenings",
      bg: "happeningBackgroundMobile",
    },
    {
      name: "concierge",
      bg: "conciergeBackgroundMobile",
    },
    {
      name: "tourist_programme",
      bg: "touristBackgroundMobile",
    },
    {
      name: "transport",
      bg: "transportBackgroundMobile",
    },
  ];
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  return (
    generalStore.selectedSite != null && isPageLoaded && (
      <Div100vh className="">
        <div className="w-full h-full bg-no-repeat bg-cover bg-bottom flex flex-col">
          <Header />
          <TimeCard />
          <div className="w-full h-full flex flex-col relative">
            <Background />

            {config != null && config.touristActive && (
              <div className="w-8/12 h-full flex flex-col p-3 mx-auto">
                <div className="flex flex-col w-full h-full gap-3 overflow-y-scroll">
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[0]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[1]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[2]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[3]} config={config} />
                      </div>

                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[4]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[5]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[6]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[7]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[8]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1"></div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[9]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {config != null && !config.touristActive && (
              <div className="w-1/3 h-full flex flex-col p-3 mx-auto">
                <div className="flex flex-col w-full h-full gap-3">
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-2 aspect-h-1">
                        <MenuCard item={listCard[0]} config={config} />
                      </div>
                      {/* <div className='flex-1 aspect-w-1 aspect-h-1'>
                                <MenuCard item={listCard[1]} config={config} />
                            </div> */}
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[1]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[2]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[3]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[4]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[5]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[6]} config={config} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <div className="flex flex-row w-full h-full gap-3">
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[7]} config={config} />
                      </div>
                      <div className="flex-1 aspect-w-1 aspect-h-1">
                        <MenuCard item={listCard[9]} config={config} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Div100vh>
    )
  );
});

export default MainMenuDesktop;
