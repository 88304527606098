const Const = {
  mainColor: "#008BAF",
  secondaryColor: "#F19743",
  yellow: "#F1C443",
  orange: "#F19743",
};

export default Const;

export const email = process.env.REACT_APP_EMAIL;
export const password = process.env.REACT_APP_PASSWORD;

export const Config = {
  adsignAccount: {
    email: process.env.ADSIGN_EMAIL,
    
  },
  api: {
    baseUrl: "https://capitaland.api.adactive.asia",
    s3Url: "https://capitaland.assets.adactive.asia",
    capitalandExternalUrl: "https://capitaland.com",
  },
  adSignConfig: [
    {
      name: "Bedok Mall",
      slug: 'bedokmall',
      siteId: 481,
      map: {
        deviceId: 2063,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 481,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2063,
        site: 481,
        zone: "asia",
      },
    },
    {
      name: "Funan",
      slug: 'bedokmall',
      siteId: 486,
      map: {
        deviceId: 2068,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 486,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2068,
        site: 486,
        zone: "asia",
      },
    },
    {
      name: "Raffles City",
      slug: 'rafflescity',
      siteId: 501,
      map: {
        deviceId: 2095,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 501,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2095,
        site: 501,
        zone: "asia",
      },
    },
    {
      name: "Bugis Junction",
      slug: 'bugisjunction',
      siteId: 482,
      map: {
        deviceId: 2084,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 482,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2084,
        site: 482,
        zone: "asia",
      },
    },
    {
      name: "IMM",
      slug: 'imm',
      siteId: 487,
      map: {
        deviceId: 2087,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 487,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2087,
        site: 487,
        zone: "asia",
      },
    },
    {
      name: "Bugis+",
      slug: 'bugis',
      siteId: 483,
      map: {
        deviceId: 2086,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 483,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2086,
        site: 483,
        zone: "asia",
      },
    },
    {
      name: "Lot One",
      slug: 'lotone',
      siteId: 495,
      map: {
        deviceId: 2090,
      },
      api: {
        endpoint: "https://capitaland.api.adactive.asia",
        site: 495,
      },
      analytics: {
        endpoint: "https://capitaland.api.adactive.asia",
        device: 2090,
        site: 495,
        zone: "asia",
      },
    },
    {
      name: "Aperia Mall",
      slug: 'aperia',
      siteId: 517,
      map: {
          deviceId: 2207
      },
      api: {
          endpoint: "https://capitaland.api.adactive.asia",
          site: 517,
          username: "2207-device",
          key: "efe49ea0f063cd608df6942ff7dc13d7"
      },
      analytics: {
          endpoint: "https://capitaland.api.adactive.asia",
          device: 2207,
          site: 517,
          zone: "asia",
          token: "3987068fbb4b7b8ec3f88f33a731c295"
      }
    },
  ],
};

export const selectedMall = async (mallId) => {
  const mallList = await listMall();
  const mall = mallList.filter((mall) => `${mall.config.siteId}` == mallId);
  return mall[0];
};

export const listMall = async () => {
  try {
    const response = await fetch('/sitesConfig.json'); // Adjust the path accordingly
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};
