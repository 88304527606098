/* eslint-disable */
import { useStores } from "../../use.store";
import React from "react";
import { observer } from "mobx-react-lite";

import { FiClock, FiWifi, IconName } from "react-icons/fi";
const TimeCard = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;
  return (
    <div className="w-screen flex flex-row divide-x-2 divide-gray-300 text-gray bg-slate-200 py-3 overflow-hidden relative px-4">
      <div className="flex-none flex flex-row  items-center gap-2 pr-2">
        <FiClock width={200} height={200} fontSize={20} />
        <div className="w-full flex flex-col ">
          <span className="text-[10px]">Opening Hours</span>
          <span className="text-[10px] font-medium">
            {config != null && config.openinghour}
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row pl-2 items-center gap-2">
        {config != null && config.wifi != null  && config.wifi != " " && <div className="flex flex-row w-full items-center gap-2">
          <FiWifi width={200} height={200} fontSize={20} />
          <div className="w-full flex flex-col  ">
            <span className="text-[10px]">Network ID</span>
            <div
              className="marquee"
              style={{
                maxWidth: config.wifipassword == null ? "120px" : "100px",
              }}
            >
              <div className="marquee-wrapper text-[10px]">
                <span>{config != null && config.wifi}</span>
              </div>
            </div>
          </div>
        </div>}
        {config != null && config.wifipassword != null && (
          <div className="w-full flex flex-col ">
            <span className="text-[10px]">Password</span>
            <div className="marquee" style={{ maxWidth: "100px" }}>
              <div className="marquee-wrapper text-[10px]">
                <span>{config != null && config.wifipassword}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default TimeCard;
