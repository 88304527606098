// @flow

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "./router/index";

import map from "@adactive/arc-map/src/MapReducers";
import type { MapReducerStateType } from "@adactive/arc-map/src/initialState";

import loadingScreen from "@adactive/arc-loading-screen/src/LoadingScreenReducer";
import { WayFindingControlsReducers } from "@adactiveasia/adasia-wayfindingcontrols";
import { ScreenSaverReducers } from "@adactive/adsum-screensaver-asia";
import { ModalReducers } from "@adactive/adsum-modal-asia";
import type { LoadingScreenReducerType } from "@adactive/arc-loading-screen/src/LoadingScreenReducer";
import { transitionReducers } from "./store/transition/transitionReducers";
import { errorReducers } from "./store/errors/errorsReducers";
import appReducer from "./store/app/appReducer";
import authReducer from "./store/app/authReducer";
import mediaReducer from "./store/app/mediaReducer";
import esplanadeReducer from "./store/app/esplanadeReducer";
import capitalandReducer from "./store/app/capitalandReducer";
import Adsign from "./store/app/adsign/reducers";

export type RoutingReducerType = {|
  location: {|
    pathname: string,
    search: string,
    hash: string,
  |},
|};

export type AppStateType = {|
  router: RoutingReducerType,
  map: MapReducerStateType,
  loadingScreen: LoadingScreenReducerType,
|};

const appState: AppStateType = {
  router: connectRouter(history),
  map,
  loadingScreen,
  modal: ModalReducers,
  screenSaver: ScreenSaverReducers,
  wayFindingControls: WayFindingControlsReducers,
  transition: transitionReducers,
  errors: errorReducers,
  app: appReducer,
  auth: authReducer,
  media: mediaReducer,
  esplanade: esplanadeReducer,
  capitaland: capitalandReducer,
  Adsign: Adsign
  // esplanade: esplanadeReducer,
};

export default combineReducers(appState);
