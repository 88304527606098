import { Tracker } from "@adactive/adsum-client-analytics";
import trackMapActions from "./trackMapActions";
import config from "../../services/Config";
import { selectedMall } from "../../utils/const";

let tracker = null;
let getMessage = (action) =>
  `Analytics tracker is not ready, action "${action.type}" will not be record`;

config.wait().then(() => {
  if (!config.config.analytics) {
    console.error(
      "Current Site does not support analytics v2, you need to enable it on Studio admin"
    );
    getMessage = () =>
      "Current Site does not support analytics v2, you need to enable it on Studio admin";

    return;
  }

  // Overwrite analytics config to use localhost API instead
  tracker = new Tracker(config.config.analytics);
  tracker.start(200);
});

const siteId = localStorage.getItem("mallId");
selectedMall(siteId)
  .then(configs => {
    if (configs) {
      tracker = new Tracker(configs.config.analytics);
      tracker.start(200);
    }
  })


const analyticsMiddleware = (store) => (next) => (action) => {
  if (tracker === null) {
    return next(action);
  }
  trackMapActions(tracker, store, action);

  return next(action);
};

export default analyticsMiddleware;
