import React from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";

//i18n
import Header from './Header';
import Lottie from 'react-lottie';

function EmptyState({title,description}) {
    const navigate = useHistory();
    return (
        <React.Fragment>
            <div className='w-full h-full flex flex-col items-center pt-[100px]'>
                <div className='w-40'>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: require(`../../assets/images/animation/not-found-2.json`),
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        style={{ flex: 1, pointerEvents: "none" }}
                    />
                </div>
                <div className='flex flex-col bg-white rounded-lg p-5 mt-4 w-80 text-center'>
                    <span className='font-medium'>{title}</span>
                    <span className='text-center mt-2'>{description}</span>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EmptyState;