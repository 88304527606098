/* eslint-disable */
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ACA from "@adactive/adsum-utils/services/ClientAPI";
import _, { ceil, unset } from "lodash";
import {
  AdsumLoader,
  AdsumWebMap,
  ArrowPathPatternOptions,
  DotPathBuilder,
  DotPathBuilderOptions,
  DotPathSectionDrawer,
  DotPathSectionDrawerOptions,
  PinUserObject,
  SingleFloorAnimation,
  TEXTURE_QUALITY,
} from "@adactive/adsum-web-map";
import { LoadingScreenActions } from "@adactive/arc-loading-screen";
import WayFindingControls, {
  WayFindingControlsActions,
} from "@adactiveasia/adasia-wayfindingcontrols";
import { MainActions, Map, SelectionActions } from "@adactive/arc-map";
import {
  resetMapAndWayFinding,
  tmtt,
} from "@adactiveasia/adasia-wayfindingcontrols/src/WayFindingControlsActions";
import placesController from "@adactive/arc-map/src/controllers/PlacesController";
import * as animationData from "../assets/lottie/Animation - Loading.json";
import {
  DistCacheManager,
  EntityManager,
  Mobile,
} from "@adactive/adsum-client-api";
import Header from "./components/Header";
import BreadCrumb from "./components/BreadCrumb";
import Background from "./components/Background";
import { observer } from "mobx-react-lite";
import { useStores } from "../use.store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Div100vh from "react-div-100vh";
import deviceConfig from "../services/Config";
import store from "../store/index";
import { labelStyle } from "../utils/constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  setAwm,
  setIsActionEnabled,
  setIsLoading,
  setMapLoaded,
  setPoiDetailMultiplePageShow,
  setSelectedMultiplePois,
} from "../store/app/appActions";
import { selectedMall } from "../utils/const";
import mapBackground from "../assets/images/map-background-transparent.png";
import Carousel, { consts } from "react-elastic-carousel";
import amenities from "../utils/amenities";
import { HiInformationCircle, HiX } from "react-icons/hi";
import SearchView from "./components/SearchView";
import MultipleModal from "./components/MultipleModal";
import Tour from "reactour";
import DetailPoi from "./components/DetailPoi";
import { checkComputedPath, getPlace, getPoi } from "../utils/functions";
import Lottie from "react-lottie";

const FloorGuideDesktop = observer(() => {
  const { generalStore } = useStores();
  const navigate = useHistory();
  const awmContainerRef = useRef(null);
  const [selectedAmenities, setSelectedAmenities] = useState(null);
  const [searchView, setShowSearchView] = useState(false);
  const [multipleShow, setMultipleShow] = useState(false);
  const {
    wayfindingState,
    currentFloor,
    isActionEnabled,
    wayFindingControlsState,
    mapLoaded,
    awm,
  } = useSelector(
    (state) => ({
      wayfindingState: state.map.wayfindingState,
      wayFindingControlsState: state.wayFindingControls,
      mapLoaded: state.app.mapLoaded,
      awm: state.app.awm,
      currentFloor: state.map.currentFloor,
      isActionEnabled: state.app.isActionEnabled,
    }),
    shallowEqual
  );
  const mapState = useSelector((state) => state.map.state);
  const dispatch = useDispatch();
  const config = generalStore.selectedSite.config;
  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isViaLift, setIsViaLift] = useState(false);
  const [destinationObject, setDestinationObject] = useState(null);
  const [percentage, setpercentage] = useState(0);
  const [doneDownloading, setdoneDownloading] = useState(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const handleMessage = event => {
        if (event.data.type === 'FETCH_PROGRESS') {
          const progress = Math.ceil(event.data.progress);
          if(progress < 100)
          setpercentage(progress);
          
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
  
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  useEffect(() => {
    selectedMall(generalStore.selectedSite.adsumSiteId).then((currentMall) => {
      initDevice(currentMall.config);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      loadMap();
    }, 1000);
  }, [mapState, mapLoaded, generalStore.allFloors]);

  useEffect(() => {
    if (generalStore.searchAction == "") {
      setShowSearchView(false);
    }
  }, [generalStore.searchAction]);

  useEffect(() => {
    if (mapLoaded && awm && generalStore.from != null) {
      handleUpdateUser(
        generalStore.from.locations[0].floor_id,
        generalStore.from.locations[0].shape_id
      ).then(() => {
        if (generalStore.to == null) {
          setstartIndex(1);
          setisTourOpen(true);
          setShowDetail(false);
        } else {
          setShowDetail(true);
        }
      });
    } else {
      setShowDetail(false);
    }
  }, [mapLoaded, awm, generalStore.from]);

  useEffect(() => {
    if (mapLoaded && awm && isActionEnabled && doneDownloading) {
      if (generalStore.to != null && generalStore.to.locations.length > 0) {
        setTimeout(() => {
          updateUIAfterDestination().catch((error) => {
            console.error("An error occurred:", error);
          });
        }, 1000);
      } else {
        resetMap();
        setShowDetail(false);
      }
    }
  }, [mapLoaded, awm, generalStore.to,doneDownloading]);

  const updateUIAfterDestination = async () => {
    await handleUpdateDestination();
  
    if (generalStore.from == null) {
      setstartIndex(0);
      setisTourOpen(true);
      setShowDetail(false);
    } else {
      setShowDetail(true);
    }
  };
  

  useEffect(() => {
    if (generalStore.tmtType == "escalator") {
      onTakeMeThere(false);
    }
    if (generalStore.tmtType == "lift") {
      onTakeMeThere(true);
    }
    if (generalStore.tmtType == "replay") {
      onTakeMeThere(isViaLift);
    }
  }, [generalStore.tmtType]);

  useEffect(() => {
    if (!wayfindingState.drawing && wayFindingControlsState.finish) {
      if (generalStore.to != null) {
        dispatch(setIsActionEnabled(true));
        generalStore.tmtType = "";
      }
    }
  }, [wayFindingControlsState, wayfindingState, generalStore.to]);

  useEffect(() => {
    // Callback function to handle mutations
    const handleMutation = () => {
      detectCanvas();
    };

    // Create a new MutationObserver
    const observer = new MutationObserver(handleMutation);

    // Start observing changes in the entire document body
    observer.observe(document.body, { subtree: true, childList: true });

    // Initial detection when the component mounts
    detectCanvas();

    // Cleanup function to disconnect the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const detectCanvas = () => {
    if (generalStore.allFloors.length == 0) {
      const canvasElements = document.getElementsByTagName("canvas");
      const hasCanvas = canvasElements.length > 0;
      dispatch(setIsActionEnabled(hasCanvas));
      dispatch(setMapLoaded(hasCanvas));
    }
  };

  const [isTourOpen, setisTourOpen] = useState(false);
  const [startIndex, setstartIndex] = useState(0);
  const [indexPlace, setIndexPlace] = useState(0);
  const accentColor = "#5cb7b7";
  const [selectedObject, setSelectedObject] = useState(null);

  const tourConfig = [
    {
      selector: '[data-tut="reactour__iso"]',
      // content: `Please fill your current location to start routing maps.`,
      content: () => (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <HiInformationCircle color="#8bbce7" size={20} />
            <span className="text-sm font-medium">Info</span>
          </div>
          <div className="w-full text-xs">
            Please fill in the current location to start the routing.
          </div>
        </div>
      ),
      position: "top",
    },
    {
      selector: '[data-tut="to"]',
      // content: `Please fill your current location to start routing maps.`,
      content: () => (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <HiInformationCircle color="#8bbce7" size={20} />
            <span className="text-sm font-medium">Info</span>
          </div>
          <div className="w-full text-xs">
            Please fill in the destination location to start the routing.
          </div>
        </div>
      ),
      position: "top",
    },
  ];

  const initDevice = (siteConfig) => {
    try {
      const awMap = new AdsumWebMap({
        loader: new AdsumLoader({
          entityManager: generalStore.aca.entityManager, // Give it in order to be used to consume REST API
          deviceId: siteConfig.map.deviceId, // The device Id to use
          wireframeSpacesOptions: { color: 0x5a5b5a },
          textureQuality: TEXTURE_QUALITY.LOW,
        }), // The loader to use
        camera: {
          centerOnOptions: {
            fitRatio: 1.3,
            minDistance: 500,
            zoom: true,
          },
        },
        engine: {
          container: awmContainerRef.current,
        },
        wayfinding: {
          pathBuilder: new DotPathBuilder(
            new DotPathBuilderOptions({
              patternSpace: 8, // 4,
              patternSize: 5, // 2
              pattern: new ArrowPathPatternOptions({
                color: "#FFBB38",
              }),
            }),
            new DotPathSectionDrawerOptions({
              center: true,
              centerOnOptions: {
                fitRatio: 3,
                minDistance: 20,
                altitude: 80,
              },
              oriented: true,
              speed: 80,
              showDelay: 1000,
            })
          ),
          pathSectionDrawer: new DotPathSectionDrawer({
            center: true,
            oriented: false,
            speed: 80,
            // showDelay: 300,
          }),
          userObject: new PinUserObject({
            color: "#be272f",
            size: 15,
          }),
        },
        scene: {
          animation: new SingleFloorAnimation({
            keepSiteVisible: false,
            bounce: false,
            center: true,
            centerOnOptions: {
              fitRatio: 1.2,
              minDistance: 200,
            },
          }),
        },
      });
      dispatch(setAwm(awMap));
      generalStore.awm = awMap;
    } catch (e) {
      console.log(e);
    }
  };

  const getDrawPathSectionOptions = (pathSection) => {
    const isVertical =
      pathSection.isInterGround() &&
      Math.abs(pathSection.getInclination()) > 10;
    const min = 600; // 400
    const max = 1800;
    // const isLandscape = pathSection.getAzimuthOrientation() < 100; //180

    return {
      drawOptions: {
        speed: isVertical ? 150 : 50,
        centerOnOptions: {
          zoom: true,
          altitude: isVertical ? 0 : 90,
          time: 1500,
          fitRatio: 1.2,
          minDistance: min,
          maxDistance: max,
        },
      },
      setCurrentFloorOptions: {
        centerOnOptions: {
          zoom: true,
          altitude: isVertical ? 0 : 90,
          time: 1500,
          minDistance: min,
          maxDistance: max,
        },
      },
    };
  };

  const loadMap = async () => {
    if (generalStore.allFloors.length == 0 && awm != null) {
      await awm.objectManager.user.setDisplayMode("none");
      const getFloors = Array.from(awm.objectManager.floors.values());
      const sortedFloors = _.sortBy(
        getFloors.map((x) => {
          const splitedText = x.name.split("");
          const orderNumber =
            splitedText[0] === "B"
              ? parseInt(-splitedText[1])
              : parseInt(splitedText[1]);
          return {
            ...x,
            order_number: orderNumber,
          };
        }),
        "order_number"
      );
      generalStore.allFloors = sortedFloors;
      // if (awm.defaultFloor != null && generalStore.to == null) {
      //   // dispatch(
      //   //   MainActions.changeFloorAction(awm.defaultFloor.id, true, true)
      //   // );
      // }
      setTimeout(() => {
        setdoneDownloading(true);
      }, 1000);
      // dispatch(setMapLoaded(true));
      // dispatch(setIsActionEnabled(true));
    }
  };

  const changeFloor = async (floorId, space = null) => {
    dispatch(MainActions.changeFloorAction(floorId, true, true));
    const floor = generalStore.allFloors.find((floor) => floor.id === floorId);

    console.log(floor);
    if (space != null) {
      setTimeout(() => {
        awm.cameraManager.centerOn(space, 1.5, true);
      }, 3000);
    }
  };

  const handleClickAmenities = async (amenity) => {
    if (selectedAmenities === amenity.name) {
      resetAmenities();
      setSelectedAmenities(null);
    } else {
      setSelectedAmenities(amenity.name);
      resetAmenities();
      const findPois = generalStore.amenitiesPois.find(
        (x) => x.name === amenity.name
      );

      if (findPois) {
        if (findPois.locations.length > 0) {
          const closestPlaces = placesController.getClosestPath(
            findPois.locations,
            true
          );
          if (closestPlaces) {
            if (closestPlaces.to.adsumObject.parent.id !== currentFloor) {
              dispatch(
                MainActions.changeFloorAction(
                  closestPlaces.to.adsumObject.parent.id,
                  true,
                  true
                )
              );
            }
          }

          findPois.locations.map((location) => {
            const labels = placesController.getPath(location.id);
            if (labels.to.adsumObject.labels) {
              labels.to.adsumObject.labels.forEach((label) => {
                label.setImage(
                  require(`../img/interactive/active/${amenity.iconName}.png`)
                );
                label.moveTo(0, 0, 0);
                label.setHeight(20);
                label.setWidth(20);
              });
              return true;
            }
          });
        }
      }
    }
  };

  const resetAmenities = () => {
    const findAmenitiesCat = generalStore.amenitiesPois.filter((poi) => {
      return poi.name === selectedAmenities;
    });

    if (findAmenitiesCat.length > 0) {
      findAmenitiesCat.forEach((amenitiesCat) => {
        if (amenitiesCat.locations.length > 0) {
          amenitiesCat.locations.map((location) => {
            const findIconName = amenities.find(
              (amenity) => amenity.name === amenitiesCat.name
            );
            if (findIconName) {
              const labels = placesController.getPath(location.id);
              if (labels.to.adsumObject.labels) {
                labels.to.adsumObject.labels.forEach((label) => {
                  label.setImage(
                    // require(`../../img/interactive/inactive/${findIconName.iconName}.png`)
                    require(`../img/interactive/inactive/${findIconName.iconName}.png`)
                  );
                  label.moveTo(0, 0, 0);
                  label.setHeight(10);
                  label.setWidth(10);
                });
                return true;
              }
            }
          });
        }
      });
    }
  };

  const handleUpdateUser = async (floor_id, shape_id) => {
    try {
      const object = awm.objectManager.getSpace(shape_id);
      const floor = await awm.objectManager.getFloor(floor_id);
      const objectPosition = object._mesh.position;

      await awm.wayfindingManager.setUserAdsumPosition(
        objectPosition,
        floor,
        false
      );
      await awm.wayfindingManager._doAdsumUserPositionUpdate(
        objectPosition,
        floor
      );
      await awm.sceneManager.setCurrentFloor(floor, true);
      await awm.cameraManager.centerOn(object, true, {
        zoom: true,
        maxDistance: 1300,
        minDistance: 200,
      });
      await awm.objectManager.user.setDisplayMode("visible");
      object.setColor("#63aeef");
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateDestination = async () => {
    try{
      
      if (selectedObject == null) {
        
        const object = awm.objectManager.getSpace(
          generalStore.to.locations[0].shape_id
        );
        
        setDestinationObject(object);
        const floor = await awm.objectManager.getFloor(
          generalStore.to.locations[0].floor_id
        );
  
        awm.objectManager.spaces.forEach((v, idx) => {
          v.resetColor();
        });
        await awm.sceneManager.setCurrentFloor(floor, true);
        await awm.cameraManager.centerOn(object, true, {
          zoom: true,
          maxDistance: 1300,
          minDistance: 200,
        });
        object.setColor("#63aeef");
      } else {
        const object = selectedObject;
        setDestinationObject(object);
        const floor = await awm.objectManager.getFloor(selectedObject.parent.id);
  
        awm.objectManager.spaces.forEach((v, idx) => {
          v.resetColor();
        });
        await awm.sceneManager.setCurrentFloor(floor, true);
        await awm.cameraManager.centerOn(object, true, {
          zoom: true,
          maxDistance: 1300,
          minDistance: 200,
        });
        object.setColor("#63aeef");
        setSelectedObject(null);
      }
    }catch(e){
      console.log("error",e)
    }
  };

  const resetMapAndWayFinding = (
    reset,
    resetMap,
    resetMapAnimatedOption,
    resetWayfinding
  ) => {
    dispatch(
      WayFindingControlsActions.resetMapAndWayFinding(
        reset,
        resetMap,
        resetMapAnimatedOption,
        resetWayfinding
      )
    );
  };

  const resetMap = async () => {
    if (awm && awm.defaultFloor) {
      setIndexPlace(0);
      dispatch(MainActions.changeFloorAction(awm.defaultFloor.id, true, true));
      await awm.objectManager.user.setDisplayMode("none");
      resetMapAndWayFinding(true, true, true, true);

      awm.objectManager.spaces.forEach((v, idx) => {
        v.resetColor();
      });
    }
  };

  const onMapClicked = async (object) => {
    if (object && object.placeId) {
      setSelectedObject(object);
      const getPois = generalStore.aca.getPoisFromPlace(object.placeId);
      const place = generalStore.aca.getPlace(object.placeId);

      if (getPois && getPois.length > 0) {
        const poiPlaces = getPois.map((poi) => {
          return generalStore.pois.find((p) => p.id == poi.id);
        });
        if (poiPlaces.length > 1) {
          generalStore.to = null;
          generalStore.multipleTo = poiPlaces;
        } else {
          generalStore.to = poiPlaces[0];
        }
      }
    }
  };

  const tmtt = (poi, poiPlace, pmr) => {
    dispatch(WayFindingControlsActions.tmtt(poi, poiPlace, pmr));
  };

  const onTakeMeThere = async (viaLift) => {
    try {
      setIsViaLift(viaLift);
      setLoading(true);
      placesController.sortAllAdsumPlaces();
      resetMapAndWayFinding(true, true, true, true);
      resetAmenities();
      setSelectedAmenities(null);
      setTimeout(async () => {
        if (generalStore.to.shape_id) {
          if (checkComputedPath(awm, generalStore.aca, destinationObject)) {
            dispatch(WayFindingControlsActions.finish(false));
            dispatch(setIsActionEnabled(false));
            if (
              getPoi(generalStore.aca, destinationObject) &&
              getPlace(generalStore.aca, destinationObject)
            )
              // await awm.cameraManager.zoom(-550);
              tmtt(
                getPoi(generalStore.aca, destinationObject)[0],
                [getPlace(generalStore.aca, destinationObject)],
                viaLift
              );
          } else {
            console.log("gaada path nyah");
          }
        } else {
          console.log("gaada path");
        }
        setLoading(false);
      }, 2000);
    } catch (e) {
      console.log(e, "error way finding");
    }
  };

  const renderMap = () => {
    return (
      <Map
        store={store}
        awm={awm}
        isOpen={true}
        className={`w-full h-full absolute z-10`}
        backgroundImage={mapBackground}
        onClick={onMapClicked}
        getDrawPathSectionOptions={getDrawPathSectionOptions}
        highlightColor={"#63aeef"}
        zoom={{
          min: 250,
          max: 1300,
        }}
      >
        <WayFindingControls
          awm={awm}
          kioskPlace={awm ? awm.defaultFloor : null}
          isArrivedLabel={false}
          interchangeLabelStyle={labelStyle.interchange}
          destinationLabelText=""
          icLabelText=" Please proceed to "
          fullCustomIcLabelText
          customResetFunction={() => {}}
        />
        <div
          id={"adsum-web-map-container"}
          className={!mapLoaded ? "adsumWebMapLoader" : "lorem"}
          ref={awmContainerRef}
        />
        {awm &&
        wayFindingControlsState &&
        wayFindingControlsState.placeDestination &&
        wayFindingControlsState.placeDestination.name &&
        awm.defaultFloor !== wayFindingControlsState.placeDestination
          ? !wayFindingControlsState.finish && (
              <div className="drawing-status">
                {wayFindingControlsState.interchangeLabel &&
                wayFindingControlsState.interchangeLabel.length > 0
                  ? wayFindingControlsState.interchangeLabel[
                      wayFindingControlsState.interchangeLabel.length - 1
                    ]
                  : "Go to nearest Lift / Escalator"}
              </div>
            )
          : null}
      </Map>
    );
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Div100vh>
      {!wayFindingControlsState.finish && (
        <div className="absolute z-[9999999] w-full h-full bg-transparent left-0 top-0" />
      )}
      {loading && (
        <div className="w-screen h-screen fixed z-[9999999] bg-[#00000066] top-0 flex flex-col items-center justify-center loading-map">
          <div className="w-[200px] h-[200px] routing-loading"></div>
          <span className="font-bold text-xs text-white mt-[-60px]">
            Finding your best path..
          </span>
        </div>
      )}
      {generalStore.to != null &&
        generalStore.from != null &&
        isActionEnabled && <DetailPoi />}
      <Tour
        onRequestClose={() => {
          setisTourOpen(false);
        }}
        steps={tourConfig}
        isOpen={isTourOpen}
        maskClassName="mask"
        className="helper"
        showNavigationNumber={false}
        showNavigation={false}
        rounded={10}
        showButtons={false}
        showNumber={false}
        highlightedMaskClassName="lorem"
        accentColor={accentColor}
        startAt={startIndex}
      />
      {searchView && <SearchView />}
      {generalStore.multipleTo.length > 1 && <MultipleModal />}
      <div className="w-full h-full flex flex-col absolute z-10 top-0 left-0 overflow-hidden">
        <Header />
        <BreadCrumb
          title="Floor Guide"
          onclick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
            dispatch(setIsActionEnabled(false));
            dispatch(setMapLoaded(false));
            dispatch(setAwm(null));
            generalStore.to = null;
            generalStore.from = null;
            generalStore.allFloors = [];
            setpercentage(0);
            setdoneDownloading(false);
          }}
          onHomeClick={() => {
            navigate.push(`map?mall=${generalStore.selectedSite.name}`);
            dispatch(setIsActionEnabled(false));
            dispatch(setMapLoaded(false));
            dispatch(setAwm(null));
            generalStore.to = null;
            generalStore.from = null;
            generalStore.allFloors = [];
            setpercentage(0);
            setdoneDownloading(false);
          }}
        />
        <Background />
        <div className="w-full h-full relative">
          <div
            className={
              !doneDownloading
                ? "w-full h-full relative  flex flex-col items-center justify-center"
                : "hide"
            }
          >
            <p className="font-medium">
              <span className={`font-bold text-[${config.primaryColor}]`}>
                {percentage}%
              </span>{" "}
              Completed
            </p>
            {percentage < 90 && (
              <span className="font-light text-sm">Downloading Map Assets</span>
            )}
            {percentage > 90 && (
              <span className="font-light text-sm elipsis-animation">Rendering and Displaying Map</span>
            )}
            <div className="grid h-[40px] w-full place-items-center overflow-x-scroll rounded-lg">
              <svg className="text-white animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
                width="24" height="24">
                <path
                  d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                  stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                  stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" className={`text-[${config.primaryColor}]`}>
                </path>
              </svg>
            </div>
          </div>
          <div className={doneDownloading ? "w-full h-full relative" : "hide"}>
            {renderMap()}
            <div
              className="absolute w-full h-full z-20 flex flex-row p-4"
              style={{ pointerEvents: "none" }}
            >
              <div className="w-1/12"></div>
              <div className="w-full h-full flex flex-col justify-between">
                {mapLoaded && isActionEnabled && (
                  <div
                    className="bg-white p-4 w-full rounded-lg flex flex-row shadow-lg gap-2"
                    style={{ pointerEvents: "all" }}
                  >
                    <div className="py-3">
                      <img
                        src={require("../assets/images/icons/pin-location.png")}
                        className="w-3"
                      />
                    </div>
                    <div className="flex-grow flex flex-col">
                      <div
                        className="flex flex-row items-center gap-3"
                        data-tut="reactour__iso"
                      >
                        <div className="bg-gray-100 flex-grow flex flex-wrap rounded-lg px-4 py-2 items-center">
                          <span
                            onClick={() => {
                              setShowSearchView(true);
                              generalStore.searchAction = "from";
                              generalStore.tmtType = "";
                              setisTourOpen(false);
                            }}
                            className={
                              generalStore.from == null
                                ? `flex-grow text-gray-400`
                                : "flex-grow text-gray-900"
                            }
                          >
                            {generalStore.from == null
                              ? "Current Location"
                              : generalStore.from.name}
                          </span>
                          {generalStore.from != null && (
                            <HiX
                              onClick={() => {
                                generalStore.from = null;
                                resetMap();
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="flex flex-row items-center gap-3 mt-2"
                        data-tut="to"
                      >
                        <div className="bg-gray-100 flex-grow flex flex-wrap rounded-lg px-4 py-2 items-center">
                          <span
                            onClick={() => {
                              setShowSearchView(true);
                              generalStore.searchAction = "to";
                              generalStore.tmtType = "";
                              setisTourOpen(false);
                            }}
                            className={
                              generalStore.to == null
                                ? `flex-grow text-gray-400`
                                : "flex-grow text-gray-900"
                            }
                          >
                            {generalStore.to == null
                              ? "Where to"
                              : generalStore.to.name}
                          </span>
                          {generalStore.to != null && (
                            <HiX
                              onClick={() => {
                                generalStore.to = null;
                                resetMap();
                                // resetMap();
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {mapLoaded && (
                  <div
                    className="w-full rounded-lg"
                    style={{ pointerEvents: "all" }}
                  >
                    <Carousel
                      className={`${!isActionEnabled && "hide"}`}
                      itemsToShow={8}
                      renderPagination={({ pages, activePage, onClick }) => {
                        return (
                          <div>
                            {pages.map((page) => {
                              const isActivePage = activePage === page;
                              return <div key={page}></div>;
                            })}
                          </div>
                        );
                      }}
                      disableArrowsOnEnd={false}
                      itemPadding={[2, 2, 2, 2]}
                      renderArrow={({ type, onClick }) => (
                        <div
                          className="bg-white shadow-2xl rounded-lg h-full flex items-center px-2"
                          style={{
                            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
                          }}
                          onClick={onClick}
                        >
                          {type === "PREV" ? (
                            <img
                              className="object-cover"
                              src={require("../assets/images/icons/arrow-left.png")}
                            />
                          ) : (
                            <img
                              className="object-cover"
                              src={require("../assets/images/icons/arrow-rights.png")}
                            />
                          )}
                        </div>
                      )}
                    >
                      {config.amenities.map((item, idx) => {
                        var amenity = amenities.filter(
                          (e) => e.name == item.description
                        )[0];
                        const findPois =
                          amenity != null
                            ? generalStore.amenitiesPois.find(
                                (x) => x.name == amenity.name
                              )
                            : null;
                        return (
                          amenity &&
                          findPois && (
                            <div
                              className="w-full h-[80px] flex flex-col"
                              key={amenity.name}
                              onClick={() => handleClickAmenities(amenity)}
                            >
                              <div
                                className={`flex flex-col h-full justify-start items-center rounded-xl ${
                                  selectedAmenities === amenity.name
                                    ? `bg-[${config.primaryColor}] text-white`
                                    : "bg-white"
                                }`}
                              >
                                <div className="basis-1/2 items-center">
                                  {amenity.icon(
                                    selectedAmenities === amenity.name
                                      ? "white"
                                      : "#5F5F5F"
                                  )}
                                </div>
                                <div className="basis-1/2 flex px-1 items-center justify-center">
                                  <p
                                    className={`${"text-dark-primary-800"} text-center text-xs`}
                                  >
                                    {amenity.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </div>
              <div className="w-1/12 px-4 overflow-y-scroll">
                {mapLoaded && isActionEnabled && (
                  <div
                    className="right-0 rounded-lg flex flex-col gap-2"
                    style={{ pointerEvents: "all" }}
                  >
                    {generalStore.allFloors
                      .slice()
                      .sort((a, b) => b.altitude - a.altitude)
                      .map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              changeFloor(item.id);
                            }}
                            className={`p-3 w-full aspect-square ${
                              currentFloor == item.id
                                ? `bg-[${config.primaryColor}] text-white`
                                : "bg-white text-gray-500"
                            } p-3 flex shadow-lg aspect-square justify-center items-center shadow-lg rounded-lg font-bold`}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
});

export default FloorGuideDesktop;
