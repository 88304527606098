const initialState = {
  config: null,
  currentSite: null,
  selectedTransport: "mrt",
  selectedStore: "all",
  selectedCategories: [],
  selectedLevels: [],
  siteId: null,
  mallId: null,
  selectedFashionCategory: null,
  selectedDineCategory: null,
  selectedDineCuisine: null,
  selectedDineFoodType: null,
  selectedDineVibes: null,
  toggleDineCategory: false,
  toggleDineCuisine: false,
  toggleDineFoodType: false,
  toggleDineVibes: false,
  selectedTourist: "privileges",
};

const capitalandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONFIG":
      return {
        ...state,
        config: action.payload,
      };
    case "SET_SITE":
        return {
          ...state,
          currentSite: action.payload,
        };
    case "SET_SELECTED_TRANSPORT":
      return {
        ...state,
        selectedTransport: action.payload,
      };
    case "SET_SELECTED_STORE":
      return {
        ...state,
        selectedStore: action.payload,
      };
    case "SET_SELECTED_CATEGORIES":
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case "SET_SELECTED_LEVELS":
      return {
        ...state,
        selectedLevels: action.payload,
      };
    case "SET_SELECTED_FASHION_CATEGORY":
      return {
        ...state,
        selectedFashionCategory: action.payload,
      };
    case "SET_SELECTED_DINE_CATEGORY":
      return {
        ...state,
        selectedDineCategory: action.payload,
      };
    case "SET_SELECTED_DINE_CUISINE":
      return {
        ...state,
        selectedDineCuisine: action.payload,
      };
    case "SET_SELECTED_DINE_FOOD_TYPE":
      return {
        ...state,
        selectedDineFoodType: action.payload,
      };
    case "SET_SELECTED_DINE_VIBES":
      return {
        ...state,
        selectedDineVibes: action.payload,
      };
    case "SET_TOGGLE_DINE_VIBES":
      return {
        ...state,
        toggleDineVibes: action.payload,
      };
    case "SET_TOGGLE_DINE_CATEGORY":
      return {
        ...state,
        toggleDineCategory: action.payload,
      };
    case "SET_TOGGLE_DINE_CUISINE":
      return {
        ...state,
        toggleDineCuisine: action.payload,
      };
    case "SET_TOGGLE_DINE_FOOD_TYPE":
      return {
        ...state,
        toggleDineFoodType: action.payload,
      };
    case "SET_SELECTED_TOURIST":
      return {
        ...state,
        selectedTourist: action.payload,
      };
    case "SET_SELECTED_MALL":
      return {
        ...state,
        mallId: action.payload.mallId,
        siteId: action.payload.siteId,
      };
    case "RESET_ALL":
      return {
        ...state,
        selectedTransport: "mrt",
        selectedStore: "all",
        selectedCategories: [],
        selectedLevels: [],
        siteId: null,
        mallId: null,
        selectedFashionCategory: null,
        selectedDineCategory: null,
        selectedDineCuisine: null,
        selectedDineFoodType: null,
        toggleDineCategory: false,
        toggleDineCuisine: false,
        toggleDineFoodType: false,
        selectedDineVibes: null,
        toggleDineVibes: false,
        selectedTourist: "privileges",
      };
    default:
      return state;
  }
};

export default capitalandReducer;
