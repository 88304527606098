// @flow

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import * as serviceWorker from './registerServiceWorker';

import store from "./store/index";
import { history } from "./router/index";

import App from "./App";
import './output.css';
import 'react-spring-bottom-sheet/dist/style.css'

const root = document.getElementById("root");
const config = {
  onUpdate: (registration) => {
    alert('New content is available; please refresh.');
    // Optionally, reload the page to load the new content
    window.location.reload();
  },
  onSuccess: (registration) => {
    console.log('Service worker registered successfully:', registration);
  }
};
const dom = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);
ReactDOM.render(dom, document.getElementById('root'));
serviceWorker.register(config);
