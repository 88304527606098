/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import React from "react";
import Div100vh from "react-div-100vh";
import { observer } from "mobx-react-lite";
import { capitalandUrl, s3Url } from "../../utils/constants";
import { useStores } from "../../use.store";
import { Config } from "../../utils/const";
import { HiLocationMarker, HiX } from "react-icons/hi";
import _ from "lodash";

const MultipleModal = observer(() => {
  const { generalStore } = useStores();
  const config = generalStore.selectedSite.config;

  return (
    <div className="w-full h-full flex flex-col bg-[#000000b3] absolute z-[999999] top-0 p-4 flex items-center justify-center">
      <div className="w-full bg-white max-h-[90vw] p-4 rounded-lg flex flex-col overflow-hidden gap-3">
        <div className="flex flex-row justify-between items-center">
          <span className="font-[600] text-[16px]">Select Tenant</span>
          <HiX size={20} onClick={() => {
            generalStore.multipleTo = [];
          }} />
        </div>
        <div className="flex-grow overflow-y-scroll flex flex-col divide-y">
          {generalStore.multipleTo.map((poi) => {
            return (
              <div
                key={poi.id}
                onClick={() => {
                  generalStore.to = poi;
                  generalStore.multipleTo = [];
                  // onSelected(poi);
                  // dispatch(setPoiDetailMultiplePageShow(false));
                }}
                className="flex flex-row rounded-lg w-100 bg-white h-24 justify-center mb-4 pt-2"
              >
                <img
                  src="default.png"
                  data-src={
                    poi.logo ? `${capitalandUrl}/${poi.logo}` : "default.png"
                  }
                  onError={(element) => {
                    element.currentTarget.src = "default.png";
                  }}
                  onLoad={(element) => {
                    element.currentTarget.src =
                      element.currentTarget.getAttribute("data-src");
                  }}
                  className="rounded-lg object-cover h-24 w-24 bg-gray-200"
                />
                <div className="flex-grow flex flex-col p-2 w-100">
                  <span className="text-sm font-medium">{poi.name}</span>

                  <div className="flex-grow flex flex-row overflow-hidden">
                    <span
                      className="text-sm font-light text-gray-500 line-clamp-1"
                      style={{ lineHeight: "1.3rem" }}
                    >
                      {_.map(poi.categoriesDetails, "name").join(", ")}
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <HiLocationMarker
                      fontSize={16}
                      color={config.primaryColor}
                    />
                    <span
                      style={{ color: `${config.primaryColor}` }}
                      className="text-sm"
                    >
                      {poi.locations.length > 0
                            ? _.map(
                                poi.locations,
                                "name"
                              )[0].replaceAll("_", " ")
                            : generalStore.selectedSite.name}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default MultipleModal;
